import React from "react";
import { Dropdown, Button, Space, Checkbox, Menu } from "antd";
import { DownOutlined, MenuOutlined } from "@ant-design/icons";

const MultipleCheckboxComponents = ({
	open = false,
	serviced = false,
	multipleOpponent = false,
	showAllOpponent = false,
	showServicedCheckbox = true,
	showMultipleOpponentCheckbox = true,
	showAllOpponentCheckbox = true,
	tableView = false,
	handleUpdateCheckbox,
}) => {
	const [openFilter, setOpenFilter] = React.useState(false);

	const dropdownArea = () => (
		<Menu
			id="tm_applied_ll_take_tour_step3"
			style={open === "1" ? { display: "none" } : {}}>
			{showServicedCheckbox && (
				<Menu.Item key={"serviced"}>
					<Checkbox
						checked={serviced}
						onChange={(event) =>
							handleUpdateCheckbox(event.target.checked, "serviced")
						}>
						Serviced
					</Checkbox>
				</Menu.Item>
			)}
			{showMultipleOpponentCheckbox && (
				<Menu.Item key={"multiple_opponent_flag"}>
					<Checkbox
						checked={multipleOpponent}
						onChange={(event) =>
							handleUpdateCheckbox(
								event.target.checked,
								"multiple_opponent_flag"
							)
						}>
						Multiple Opponents
					</Checkbox>
				</Menu.Item>
			)}
			{showAllOpponentCheckbox && (
				<Menu.Item key={"show_all_opponents"}>
					<Checkbox
						checked={showAllOpponent}
						onChange={(event) =>
							handleUpdateCheckbox(event.target.checked, "show_all_opponents")
						}>
						Show All Opponent
					</Checkbox>
				</Menu.Item>
			)}
		</Menu>
	);

	return tableView ? (
		dropdownArea()
	) : (
		<Dropdown
			className="deadline_management_multiple_checkbox_area"
			dropdownRender={() => dropdownArea()}
			overlayClassName="Filters-Menu"
			open={openFilter || open}
			onOpenChange={(event) => {
				setOpenFilter(event);
			}}
			selectable>
			<Button
				className={`${
					serviced || multipleOpponent || showAllOpponent
						? "Filters-Button-Selected"
						: "Filters-Button-Empty"
				}`}
				style={{ background: "inherit" }}>
				<Space>
					<MenuOutlined />
					<DownOutlined />
				</Space>
			</Button>
		</Dropdown>
	);
};

export default MultipleCheckboxComponents;
