export const GET_DM_FILTER_PROPRIETOR = "GET_DM_FILTER_PROPRIETOR";
export const GET_DM_FILTER_CLASSES = "GET_DM_FILTER_CLASSES";
export const GET_DM_FILTER_TRADEMARK_TYPE = "GET_DM_FILTER_TRADEMARK_TYPE";
export const GET_DM_FILTER_TAG = "GET_DM_FILTER_TAG";
export const GET_DM_FILTER_TRADEMARK_STATUS = "GET_DM_FILTER_TRADEMARK_STATUS";
export const GET_DM_FILTER_TRADEMARK_STATES = "GET_DM_FILTER_TRADEMARK_STATES";
export const GET_DM_FILTER_TRADEMARK_OPPONENT = "GET_DM_FILTER_TRADEMARK_OPPONENT";
export const GET_DM_FILTER_ALERTS = "GET_DM_FILTER_ALERTS";

export const GET_DM_FILTER_RENEWAL_PROPRIETOR = "GET_DM_FILTER_RENEWAL_PROPRIETOR";
export const GET_DM_FILTER_RENEWAL_CLASSES = "GET_DM_FILTER_RENEWAL_CLASSES";
export const GET_DM_FILTER_RENEWAL_TRADEMARK_TYPE = "GET_DM_FILTER_RENEWAL_TRADEMARK_TYPE";
export const GET_DM_FILTER_RENEWAL_TAG = "GET_DM_FILTER_RENEWAL_TAG";
export const GET_DM_FILTER_RENEWAL_TRADEMARK_STATUS = "GET_DM_FILTER_RENEWAL_TRADEMARK_STATUS";
export const GET_DM_FILTER_RENEWAL_TRADEMARK_STATES = "GET_DM_FILTER_RENEWAL_TRADEMARK_STATES";
export const GET_DM_FILTER_RENEWAL_TRADEMARK_OPPONENT = "GET_DM_FILTER_RENEWAL_TRADEMARK_OPPONENT";
export const GET_DM_FILTER_RENEWAL_ALERTS = "GET_DM_FILTER_RENEWAL_ALERTS";

export const UPDATE_DM_CONTAINS_TERM = "UPDATE_DM_CONTAINS_TERM";
export const UPDATE_DM_SEE_ALL_HEARINGS = "UPDATE_DM_SEE_ALL_HEARINGS"
export const UPDATE_DM_FILTER_SELECTED_VALUES = "UPDATE_DM_FILTER_SELECTED_VALUES"
export const UPDATE_DM_FILTER_SEARCH_VALUE = "UPDATE_DM_FILTER_SEARCH_VALUE"
export const UPDATE_DM_FILTER_NO_TAG = "UPDATE_DM_FILTER_NO_TAG"
export const UPDATE_DM_FILTER_DATE_VALUES = "UPDATE_DM_FILTER_DATE_VALUES"
export const UPDATE_DM_IS_FILTER_APPLIED = "UPDATE_DM_IS_FILTER_APPLIED"
export const UPDATE_DM_BOOLEAN_FILTERS_VALUES = "UPDATE_DM_BOOLEAN_FILTERS_VALUES"

export const EMPTY_DM_FILTER_STRUCTURE = "EMPTY_DM_FILTER_STRUCTURE"

export const GET_DM_LIST_DATA = "GET_DM_LIST_DATA"
export const GET_DM_RENEWAL_LIST_DATA = "GET_DM_RENEWAL_LIST_DATA"

export const GET_TRADEMARK_DM_LIST_DATA = "GET_TRADEMARK_DM_LIST_DATA"