// Action for Deadline Management Settings

import {
	default_filter_page,
	default_filter_page_size,
} from "../../../../components/Watch/watchFilter/utils/utils";
import mikeAxios from "../../../../configs/mikeAxios";
import { MGR_RT_URL } from "../../../../configs/rootConfigs";
import {
    ERROR,
	LOADING,
	SUCCESS,
} from "../../../../constants/commonConstants/generalConstants";
import { onResponseError } from "../../../../functions/commonFunctions/interceptor";

export function getDeadlineManagementSettings({
	mm_type,
	onLoading,
	onSuccess,
	onError,
}) {
	return async (dispatch) => {
		onLoading(true);
		try {
			let response;
			response = await mikeAxios.get(`${MGR_RT_URL}/deadline_settings/`, {
				params: {
					mm_type: mm_type,
				},
				headers: { Authorization: "Token " + localStorage.getItem("token") },
			});
			onSuccess(response?.data);
		} catch (err) {
			onResponseError(err);
			onError(err);
		}
	};
}

export function updateDeadlineManagementSetting({
	newSetting,
	onLoading,
	onSuccess,
	onError,
}) {
	return async (dispatch) => {
		onLoading(true);
		try {
			let response;
			response = await mikeAxios.patch(
				`${MGR_RT_URL}/deadline_settings/`,
				newSetting
			);
			onSuccess(response?.data);
		} catch (err) {
			onResponseError(err);
			onError(err);
		}
	};
}

export function getDeadlineManagementFilter(body) {
	return async (dispatch) => {
		dispatch({
			type: `GET_DM_FILTER_${body?.required_filter}`,
			status: LOADING,
			payload: {
				required_filter: body?.required_filter,
				filing_document_type: body?.filing_document_type,
				dashboardType: body?.dashboardType,
			},
		});
		try {
			let params = {
				pseudo_flag: body?.pseudo_flag ?? false,
				mm_type: body?.mm_type ?? "",
				required_filter: body?.required_filter ?? "",
				filter_contains_term: body?.filter_contains_term ?? "",
				upcoming: body?.upcoming ?? false,
				filing_document_type: body?.filing_document_type ?? "",
				page: body?.page ?? default_filter_page,
				page_size: body?.page_size ?? default_filter_page_size,
			};
			let response = await mikeAxios.get(
				`${MGR_RT_URL}/trademarks/deadline/pending_replies/`,
				{
					params: params,
					headers: { Authorization: "Token " + localStorage.getItem("token") },
				}
			);
			dispatch({
				type: `GET_DM_FILTER_${body?.required_filter}`,
				status: SUCCESS,
				payload: {
					data: response?.data,
					page: body?.page ?? 1,
					required_filter: body?.required_filter,
					filing_document_type: body?.filing_document_type,
					dashboardType: body?.dashboardType,
				},
			});
		} catch (err) {
			onResponseError(err);
			dispatch({
				type: `GET_DM_FILTER_${body?.required_filter}`,
				status: ERROR,
				payload: {
					data: [],
					page: body?.page ?? 1,
					required_filter: body?.required_filter,
					filing_document_type: body?.filing_document_type,
					dashboardType: body?.dashboardType,
				},
			});
		}
	};
}

export function getDeadlineManagementData(body) {
	return async (dispatch) => {
        dispatch({
			type: `GET_DM_LIST_DATA`,
			status: LOADING,
			payload: {
				filing_document_type: body?.filing_document_type,
				dashboardType: body?.dashboardType,
                page: body?.page ?? 1,
			},
		});
		try {
			let params = {
				page: body?.page ?? 1,
				page_size: 100,
			};

			let requestBody = {
				pseudo_flag: body?.pseudo_flag ?? false,
				mm_type: body?.mm_type ?? "",
				upcoming: body?.upcoming ?? false,
				filing_document_type: body?.filing_document_type ?? "",
				filter_flag: body?.filter_flag ?? false,
				filters: body?.filters ?? {},
			};

			let response = await mikeAxios.put(
				`${MGR_RT_URL}/trademarks/deadline/pending_replies/`,
				requestBody,
				{
					params: params,
					headers: { Authorization: "Token " + localStorage.getItem("token") },
				}
			);
            dispatch({
				type: `GET_DM_LIST_DATA`,
				status: SUCCESS,
				payload: {
					data: response?.data,
					page: body?.page ?? 1,
					filing_document_type: body?.filing_document_type,
					dashboardType: body?.dashboardType,
				},
			});
		} catch (err) {
			onResponseError(err);
            dispatch({
				type: `GET_DM_LIST_DATA`,
				status: ERROR,
				payload: {
					data: [],
					page: body?.page ?? 1,
					filing_document_type: body?.filing_document_type,
					dashboardType: body?.dashboardType,
				},
			});
		}
	};
}

export function updateDeadlineManagementReplyFiledOrNotMyClient({
	body,
	onLoading,
	onSuccess,
	onError,
}) {
	return async (dispatch) => {
		onLoading?.(true);
		try {
			let response;
			response = await mikeAxios.patch(
				`${MGR_RT_URL}/trademarks/deadline/pending_replies/`,
				body
			);
			onSuccess?.(response?.data);
		} catch (err) {
			onResponseError(err);
			onError?.(err);
		}
	};
}

export function updateDeadlineManagementCreateReport({
	body,
	onLoading,
	onSuccess,
	onError,
}) {
	return async (dispatch) => {
		onLoading?.(true);
		try {
			let response;
			response = await mikeAxios.post(
				`${MGR_RT_URL}/trademarks/deadline/pending_replies/`,
				body
			);
			onSuccess?.(response?.data);
		} catch (err) {
			onResponseError(err);
			onError?.(err);
		}
	};
}


export function updateDeadlineManagementContainsTerm(body) {
	return async (dispatch) => {
		dispatch({
			type: `UPDATE_DM_CONTAINS_TERM`,
			payload: body,
		});
	};
}

export function updateDeadlineManagementSeeAllHearings(body) {
	return async (dispatch) => {
		dispatch({
			type: `UPDATE_DM_SEE_ALL_HEARINGS`,
			payload: body,
		});
	};
}

export function updateDeadlineManagementFilterSelectedValues(body) {
    return async (dispatch) => {
        dispatch({
            type: `UPDATE_DM_FILTER_SELECTED_VALUES`,
            payload: body,
        });
    };
}

export function updateDeadlineManagementFilterSearchValue(body) {
    return async (dispatch) => {
        dispatch({
            type: `UPDATE_DM_FILTER_SEARCH_VALUE`,
            payload: body,
        });
    };
}

export function updateDeadlineManagementFilterNoTags(body){
    return async (dispatch) => {
        dispatch({
            type: `UPDATE_DM_FILTER_NO_TAG`,
            payload: body,
        });
    };
}

export function updateDeadlineManagementFilterDateValues(body){
    return async (dispatch) => {
        dispatch({
            type: `UPDATE_DM_FILTER_DATE_VALUES`,
            payload: body,
        });
    };
}

export function updateDeadlineManagementIsFilterApplied(body){
    return async (dispatch) => {
        dispatch({
            type: `UPDATE_DM_IS_FILTER_APPLIED`,
            payload: body,
        });
    };
}

export function emptyDeadlineManagementFilterStructure(body){
    return async (dispatch) => {
        dispatch({
            type: `EMPTY_DM_FILTER_STRUCTURE`,
            payload: body,
        });
    };
}

export function updateDeadlineManagementBooleanFilterValues(body) {
	return async (dispatch) => {
		dispatch({
			type: `UPDATE_DM_BOOLEAN_FILTERS_VALUES`,
			payload: body,
		});
	};
}

export function getDeadlineManagementRenewalFilter(body) {
	return async (dispatch) => {
		dispatch({
			type: `GET_DM_FILTER_RENEWAL_${body?.required_filter}`,
			status: LOADING,
			payload: {
				required_filter: body?.required_filter,
				filing_document_type: body?.filing_document_type,
				dashboardType: body?.dashboardType,
			},
		});
		try {
			let params = {
				pseudo_flag: body?.pseudo_flag ?? false,
				required_filter: body?.required_filter ?? "",
				filter_contains_term: body?.filter_contains_term ?? "",
				upcoming: body?.upcoming ?? false,
				page: body?.page ?? default_filter_page,
				page_size: body?.page_size ?? default_filter_page_size,
			};
			let response = await mikeAxios.get(
				`${MGR_RT_URL}/trademarks/deadline/renewals/`,
				{
					params: params,
					headers: { Authorization: "Token " + localStorage.getItem("token") },
				}
			);
			dispatch({
				type: `GET_DM_FILTER_RENEWAL_${body?.required_filter}`,
				status: SUCCESS,
				payload: {
					data: response?.data,
					page: body?.page ?? 1,
					required_filter: body?.required_filter,
					filing_document_type: body?.filing_document_type,
					dashboardType: body?.dashboardType,
				},
			});
		} catch (err) {
			onResponseError(err);
			dispatch({
				type: `GET_DM_FILTER_RENEWAL_${body?.required_filter}`,
				status: ERROR,
				payload: {
					data: [],
					page: body?.page ?? 1,
					required_filter: body?.required_filter,
					filing_document_type: body?.filing_document_type,
					dashboardType: body?.dashboardType,
				},
			});
		}
	};
}

export function getDeadlineManagementRenewalData(body) {
	return async (dispatch) => {
        dispatch({
			type: `GET_DM_RENEWAL_LIST_DATA`,
			status: LOADING,
			payload: {
				filing_document_type: body?.filing_document_type,
				dashboardType: body?.dashboardType,
                page: body?.page ?? 1,
			},
		});
		try {
			let params = {
				page: body?.page ?? 1,
				page_size: 100,
			};

			let requestBody = {
				pseudo_flag: body?.pseudo_flag ?? false,
				upcoming: body?.upcoming ?? false,
				filter_flag: body?.filter_flag ?? false,
				filters: body?.filters ?? {},
			};

			let response = await mikeAxios.put(
				`${MGR_RT_URL}/trademarks/deadline/renewals/`,
				requestBody,
				{
					params: params,
					headers: { Authorization: "Token " + localStorage.getItem("token") },
				}
			);
            dispatch({
				type: `GET_DM_RENEWAL_LIST_DATA`,
				status: SUCCESS,
				payload: {
					data: response?.data,
					page: body?.page ?? 1,
					filing_document_type: body?.filing_document_type,
					dashboardType: body?.dashboardType,
				},
			});
		} catch (err) {
			onResponseError(err);
            dispatch({
				type: `GET_DM_RENEWAL_LIST_DATA`,
				status: ERROR,
				payload: {
					data: [],
					page: body?.page ?? 1,
					filing_document_type: body?.filing_document_type,
					dashboardType: body?.dashboardType,
				},
			});
		}
	};
}

export function updateDeadlineManagementRenewalReplyFiled({
	body,
	onLoading,
	onSuccess,
	onError,
}) {
	return async (dispatch) => {
		onLoading?.(true);
		try {
			let response;
			response = await mikeAxios.patch(
				`${MGR_RT_URL}/trademarks/deadline/renewals/`,
				body
			);
			onSuccess?.(response?.data);
		} catch (err) {
			onResponseError(err);
			onError?.(err);
		}
	};
}

export function getTrademarkDeadlineManagementData(body) {
	return async (dispatch) => {
		const isRenewal = body?.isRenewal === true;

		const actionType = `GET_TRADEMARK_DM_LIST_DATA`;

		const url = isRenewal
			? `${MGR_RT_URL}/trademarks/deadline/renewals/`
			: `${MGR_RT_URL}/trademarks/deadline/pending_replies/`;

		dispatch({
			type: actionType,
			status: LOADING,
			payload: {
				filing_document_type: body?.filing_document_type,
				dashboardType: body?.dashboardType,
				page: body?.page ?? 1,
			},
		});

		try {
			let params = {
				page: body?.page ?? 1,
				page_size: 100,
			};

			let requestBody = {
				pseudo_flag: body?.pseudo_flag ?? false,
				upcoming: body?.upcoming ?? false,
				filter_flag: body?.filter_flag ?? false,
				filters: body?.filters ?? {},
				management_mark_id: body?.management_mark_id ?? null,
			};

			if (!isRenewal) {
				requestBody.mm_type = body?.mm_type ?? "";
				requestBody.filing_document_type = body?.filing_document_type ?? "";
			}

			let response = await mikeAxios.put(url, requestBody, {
				params,
				headers: { Authorization: "Token " + localStorage.getItem("token") },
			});

			dispatch({
				type: actionType,
				status: SUCCESS,
				payload: {
					data: response?.data,
					page: body?.page ?? 1,
					filing_document_type: body?.filing_document_type,
					dashboardType: body?.dashboardType,
				},
			});
		} catch (err) {
			onResponseError(err);
			dispatch({
				type: actionType,
				status: ERROR,
				payload: {
					data: [],
					page: body?.page ?? 1,
					filing_document_type: body?.filing_document_type,
					dashboardType: body?.dashboardType,
				},
			});
		}
	};
}

export function updateDeadlineManagementRenewalCreateReport({
	body,
	onLoading,
	onSuccess,
	onError,
}) {
	return async (dispatch) => {
		onLoading?.(true);
		try {
			let response;
			response = await mikeAxios.post(
				`${MGR_RT_URL}/trademarks/deadline/renewals/`,
				body
			);
			onSuccess?.(response?.data);
		} catch (err) {
			onResponseError(err);
			onError?.(err);
		}
	};
}