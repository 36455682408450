import React, { Component } from "react";
import { connect } from "react-redux";
import deepFreeze from "deep-freeze";
import { Route } from "react-router-dom";
import { Layout, Row, Col } from "antd";

import TotalTrademark from "./YourTrademak/TotalTrademark";
import TLAOpposition from "./YourTrademak/UpcomingHearings";

import CustomTrademark from "./DocketedTrademark/Custom-Trademark";
import CustomTrademarkProfile from "./DocketedTrademark/Custom-Trademark-Profile";
import InternationalTrademark from "./DocketedTrademark/International-Trademark";
import InternationalTrademarkProfile from "./DocketedTrademark/International-Trademark-Profile";

import TrademarkProfile from "./Trademark-Profile";
import NewTrademarkProfile from '../../searchComponents/Routes/TrademarkProfile'
import ProprietorProfile from "./Proprietor-Profile";
import ManageTrademark from "./Manage-Trademark";
import TrademarkDashboardNew from "./YourTrademak/TrademarkDashboardNew";
import OpposedTrademarkDashboardNew from "./OpposedTrademark/OpposedTrademarkDashboardNew";
import DeadlineManagement from "../deadlineManagement/DeadlineManagement";

const { Content } = Layout;

class TrademarkLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Layout className="Trademark">
        <Content style={{ margin: "0px" }}>
          <Row
            className="content-container vertical_scroll"
            style={{
              height: `${
                this.props.managerAlert.length > 0
                  ? "calc(100vh - 40px)"
                  : "calc(100vh - 0px)"
              }`,
            }}
          >
            <Col span={24}>
              <Route
                path="/manager/trademark/your-trademark/dashboard"
                component={TrademarkDashboardNew}
              />
              <Route
                path="/manager/trademark/your-trademark/total-marks"
                component={TotalTrademark}
              />
              <Route
                path="/manager/trademark/your-trademark/reply-to-correspondence"
                component={DeadlineManagement}
              />
              <Route
                path="/manager/trademark/your-trademark/upcoming-hearings"
                component={TLAOpposition}
              />

              <Route
                path="/manager/trademark/opposed-trademark/dashboard"
                component={OpposedTrademarkDashboardNew}
              />
              <Route
                path="/manager/trademark/opposed-trademark/total-marks"
                component={TotalTrademark}
              />
              <Route
                path="/manager/trademark/opposed-trademark/reply-to-correspondence"
                component={DeadlineManagement}
              />
              <Route
                path="/manager/trademark/opposed-trademark/upcoming-hearings"
                component={TLAOpposition}
              />
              <Route
                path="/manager/trademark/opposed-trademark/status-tracker"
                component={TotalTrademark}
              />

              <Route
                path="/manager/trademark/docketed-trademark/custom-trademark"
                component={CustomTrademark}
              />
              <Route
                path="/manager/trademark/docketed-trademark/custom-trademark-profile/"
                component={CustomTrademarkProfile}
              />
              <Route
                path="/manager/trademark/docketed-trademark/international-trademark"
                component={InternationalTrademark}
              />
              <Route
                path="/manager/trademark/docketed-trademark/international-trademark-profile/"
                component={InternationalTrademarkProfile}
              />

              <Route
                path="/manager/trademark/trademark-profile"
                component={TrademarkProfile}
              />
              <Route
                path="/manager/trademark/opposed-trademark/trademark-profile/:id"
                component={TrademarkProfile}
              />
              <Route
                path="/manager/trademark/new-trademark-profile"
                component={NewTrademarkProfile}
              />
              <Route
                path="/manager/trademark/proprietor-profile"
                component={ProprietorProfile}
              />
              <Route
                path="/manager/trademark/manage-trademark"
                component={ManageTrademark}
              />
            </Col>
          </Row>
        </Content>
      </Layout>
    );
  }
}

const mapDispatchToProps = {};

function mapStateToProps(state) {
  return deepFreeze({
    managerAlert: state.alertMessages.manager,
  });
}

export default connect(mapStateToProps, mapDispatchToProps)(TrademarkLayout);
