import { Alert, Button, Checkbox, Modal, Spin, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
	getDeadlineManagementSettings,
	updateDeadlineManagementSetting,
} from "../../../../redux/actions/managerActions/deadlineManagementDashboard";

const { Text } = Typography;

const DeadlineSettingsComponent = (props) => {
	const { open = false, setOpen, mm_type } = props;
	const [loading, setLoading] = useState(true);
	const [deadlineSettings, setDeadlineSettings] = useState({});

	const onClose = () => {
		setOpen(false);
	};

	const generateFinalSettings = (settings, type) => {
		const yourPartySettings = [
			{
				filing_document: "Formalities Chk Fail",
				filing_key: "check_fail",
				dou_key: "dou_check_fail",
				dos_key: "dos_check_fail",
			},
			{
				filing_document: "Reply To Examination Report",
				filing_key: "exam_report",
				dou_key: "dou_exam_report",
				dos_key: "dos_exam_report",
			},
			{
				filing_document: "Filing Counter Statement",
				filing_key: "filing_counter_statement",
				dou_key: "dou_filing_counter_statement",
				dos_key: "dos_filing_counter_statement",
			},
			{
				filing_document: "Filing of Rule 46 Evidence Affidavit",
				filing_key: "filing_rule_46",
				dou_key: "dou_filing_rule_46",
			},
		];

		const thirdPartySettings = [
			{
				filing_document: "Filing of Rule 45 Evidence Affidavit",
				filing_key: "check_fail",
				dou_key: "dou_filing_rule_45",
				dos_key: "dou_filing_rule_45",
			},
			{
				filing_document: "Filing of Rule 47 Evidence Affidavit",
				filing_key: "filing_rule_47",
				dou_key: "dou_filing_rule_47",
			},
		];

		const selectedSettings =
			type === "Portfolio trademark" ? yourPartySettings : thirdPartySettings;

		return {
			settings: selectedSettings.map((s) => ({
				filing_document: s.filing_document,
				filing_key: s.filing_key,
				is_date_of_upload: settings?.setting?.[s.dou_key] ?? false,
				is_date_of_service: s.dos_key
					? settings?.setting?.[s.dos_key] ?? false
					: null,
			})),
			processing: settings?.processing ?? false,
		};
	};

	const dispatch = useDispatch();

	const fetchDeadlineSettings = () => {
		// code to fetch deadline settings
		dispatch(
			getDeadlineManagementSettings({
				mm_type: mm_type,
				onLoading: () => setLoading(true),
				onSuccess: (response) => {
					setLoading(false);
					setDeadlineSettings(response);
				},
				onError: () => {
					setLoading(false);
					setDeadlineSettings({});
				},
			})
		);
	};

	const updateDeadlineSetting = () => {
		let newSetting ={
			...deadlineSettings.setting,
			mm_type: mm_type,
		}
		dispatch(
			updateDeadlineManagementSetting({
				newSetting: newSetting ?? {},
				onLoading: () => setLoading(true),
				onSuccess: () => {
					setLoading(false);
					onClose();
				},
				onError: () => {
					setLoading(false);
				},
			})
		);
	};

	useEffect(() => {
		fetchDeadlineSettings();
	}, []);

	const isDisabled = (key) =>{
		return deadlineSettings?.processing || loading
	}

	const restrictedDisabled = (setting) =>{
		return setting.filing_key === "filing_rule_46" || setting.filing_key === "filing_rule_47";
	}

	const onCheckboxClicked = (e, setting, changedKey) => {
		const isChecked = e.target.checked;

		const updatedKey = setting.filing_key;

		// Clone state and update the right key
		const updatedDeadlineSettings = {
			...deadlineSettings,
			setting: {
				...deadlineSettings.setting,
				[changedKey.includes("upload")
					? `dou_${updatedKey}`
					: `dos_${updatedKey}`]: isChecked,
			},
		};

		setDeadlineSettings(updatedDeadlineSettings);
	};

	const singleSetting = (setting, index) => {
		return (
			<div key={index} style={{ marginLeft: "10px", marginTop: "10px" }}>
				<Text strong>
					{index + 1}. {setting.filing_document}
				</Text>
				<div style={{ marginLeft: "10px", marginTop: "5px" }}>
					{setting.is_date_of_upload !== null && (
						<Checkbox
							checked={setting.is_date_of_upload}
							disabled={isDisabled(setting.filing_key) || restrictedDisabled(setting)}
							onChange={(e) =>
								onCheckboxClicked(e, setting, "is_date_of_upload")
							}>
							Date of Upload
						</Checkbox>
					)}
					<br />
					{setting.is_date_of_service !== null && (
						<Checkbox
							checked={setting.is_date_of_service}
							disabled={isDisabled(setting.filing_key)}
							onChange={(e) =>
								onCheckboxClicked(e, setting, "is_date_of_service")
							}>
							Date of Service
						</Checkbox>
					)}
				</div>
			</div>
		);
	};

	return (
		<Modal
			title="Update Settings"
			open={open}
			footer={[
				<Button key="cancel" type="default" onClick={onClose}>
					Cancel
				</Button>,
				<Button
					loading={loading}
					disabled={deadlineSettings?.processing}
					key="submit"
					type="primary"
					onClick={() => updateDeadlineSetting()}>
					Apply Changes
				</Button>,
			]}
			onCancel={onClose}>
			<Spin spinning={loading}>
				{/* Deadline settings form */}

				{generateFinalSettings(deadlineSettings, mm_type)?.settings?.map(
					(setting, index) => {
						return singleSetting(setting, index);
					}
				)}
				<br />
				{generateFinalSettings(deadlineSettings, mm_type)?.processing && (
					<Alert
						message="Processing changes. Please wait while settings are updated."
						type="warning"
					/>
				)}
				{!generateFinalSettings(deadlineSettings, mm_type)?.processing && (
					<Alert
						message="After applying changes.It will take time to reflect in the system."
						type="warning"
					/>
				)}
			</Spin>
		</Modal>
	);
};

export default DeadlineSettingsComponent;
