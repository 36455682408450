import React from "react";
import { useDispatch } from "react-redux";
import CommonSingleDateFilter from "../../../commonComponents/filters/CommonSingleDateFilter";
import { updateDeadlineManagementFilterDateValues } from "../../../../../redux/actions/managerActions/deadlineManagementDashboard";
// import { changeUnPublishedPatentDateFilterValues } from "../../../../../../redux/actions/managerActions/unPublishedPatentDashboard/managePatent";

const SingleDateFilterContainer = (props) => {
	const { filing_document_type, dashboardType } = props;
	const dispatch = useDispatch();

	const handleDateChange = (filter_key, value) => {
		let body = {
			filing_document_type: filing_document_type,
			dashboardType: dashboardType,
			required_filter: filter_key,
			value: value,
		};
        dispatch(updateDeadlineManagementFilterDateValues(body))
	};

	const handleProposedChange = (filter_key, value) => {
		// code for proposed feature here...;
	};

	return (
		<CommonSingleDateFilter
			{...props}
			handleDateChange={handleDateChange}
			handleProposedChange={handleProposedChange}
		/>
	);
};

export default SingleDateFilterContainer;
