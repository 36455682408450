import React from "react";
import CommonInfoModal from "../../../commonComponents/modals/commonInfoModal";

const NewReplyFiled = (props) => {
	const { open, setOpen, single= false, data=[],handleSubmit,loading } = props;

	const singleInfoText = props.applied_for ? (
		<p>
			Are you sure you want to remove{" "}
			<span>
				<strong>{data?.applied_for}</strong>
			</span>{" "}
			from this table?
		</p>
	) : (
		<p>
			Are you sure you want to remove selected (
			<span>
				<strong>{data?.application_number}</strong>
			</span>
			) marks from this table?
		</p>
	);

    const multipleInfoText = (
        <p>
			Are you sure you want to remove selected (
			<span>
				<strong>{data?.length ?? 0 }</strong>
			</span>
			) marks from this table?
		</p>
    )

    const infoText = single ? singleInfoText : multipleInfoText;

    const saveButtonExtraAction = ()=>{
        let body = {
            is_reply_filed: true
        }
        handleSubmit?.(body)
    }

	const childProps = {
		open: open,
		setOpen: setOpen,
		loading: loading,
		title: "Confirm Remove",
		infoText: infoText,
        saveButtonExtraAction: saveButtonExtraAction
	};

	return <CommonInfoModal {...childProps} />;
};

export default NewReplyFiled;
