import React, { Component } from "react";
import { connect } from "react-redux";
import deepFreeze from "deep-freeze";
import { MailOutlined, UserOutlined } from "@ant-design/icons";
import { Form } from "@ant-design/compatible";

// import '@ant-design/compatible/assets/index.css';

import {
  Avatar,
  Tooltip,
  Row,
  Col,
  Card,
  Input,
  Button,
  notification,
  Typography,
  Popconfirm,
  Badge,
} from "antd";

//--------ACTIONS--------
import {
  getProfile,
  editProfile,
} from "../../../redux/actions/commonActions/actions";
import UploadLetterHead from "./UploadLetterHead";

//--------CONSTANTS--------
import {
  LOADING,
  SUCCESS,
  ERROR,
} from "../../../constants/commonConstants/generalConstants";
import { checkValue } from "../../../functions/commonFunctions/checkValueFunctions";
import { changeStateValue_L1 } from "../../../functions/commonFunctions/generalFunctions";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import "../../../stylesheets/profile.css";
import { CKEditor } from "ckeditor4-react";

const { Text } = Typography;

class EditProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userEnable: false,
      disclaimerEnable: false,
      modalsVisibility: {
        uploadLogo: false,
      },
	  editorValue: props.userProfile?.disclaimer || "", 
    };
    this.changeStateValue_L1 = changeStateValue_L1.bind(this);
  }

  async handleProfileUpdate(event) {
    event?.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
		const updatedValues = {
			...values,
			disclaimer: this.state.editorValue,
		}
        await this.props.editProfile(updatedValues);
        if (this.props.userProfileStatus === SUCCESS) {
          this.updateProfile(SUCCESS);
          this.setState({
            userEnable: false,
            disclaimerEnable: false,
          });
          this.props.getProfile();
        } else {
          this.updateProfile(ERROR, this.props?.userProfileError);
        }
      }
    });
  }

  updateProfile(type, errorMessage) {
    switch (type) {
      case SUCCESS:
        notification["success"]({
          message: "Profile Update",
          description: "Your Profile has been Updated Successfully",
        });
        break;

      case ERROR:
        notification["error"]({
          message: "Error",
          description: errorMessage
            ? errorMessage
            : "Please check your information.",
        });
        break;

      default:
        break;
    }
  }

  onEditorChange = (event) => {
	const data = event.editor.getData();
	this.setState({ editorValue: data });
	this.props.form.setFieldsValue({
	  disclaimer: data,
	});
  };


  render() {
    const { getFieldDecorator } = this.props.form;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 12 },
      },
    };

    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 16,
          offset: 4,
        },
      },
    };

    const disclaimerTitle = (
			<div style={{ display: "flex", flexDirection: "column",marginTop: "10px" }}>
				<Typography.Text strong style={{ fontSize: "16px" }}>
					DISCLAIMER
				</Typography.Text>
				<Typography.Text type="secondary" style={{ fontSize: "14px" }}>
					Please provide a Disclaimer that will be displayed on your pdf Report.
				</Typography.Text>
			</div>
		);

    return (
			<div>
				{/* profile-edit */}
				<Row>
					<Col span={24} className="m-top-20">
						<Card title="USER PROFILE DETAIL">
							<Row>
								<Col span={12} className="m-top-20">
									<Form {...formItemLayout}>
										<Form.Item
											label="User Name"
											style={{
												display: "flex",
												alignItems: "center",
												justifyContent: "center",
												margin: "0px",
											}}>
											{getFieldDecorator("name", {
												rules: [
													{
														required: true,
														message: "Please input your username!",
													},
													{
														max: 100,
														message:
															"Username cannot be more than 100 characters",
													},
												],
												initialValue: checkValue(this.props.userProfile)
													? this.props.userProfile.name
													: "",
											})(
												this.state.userEnable ? (
													<Input
														maxLength={100}
														prefix={
															<UserOutlined
																style={{ color: "rgba(0,0,0,.25)" }}
															/>
														}
														type="text"
														placeholder="User Name"
													/>
												) : (
													<Text>
														{checkValue(this.props.userProfile)
															? this.props.userProfile.name
															: "user name"}
													</Text>
												)
											)}
										</Form.Item>
										<Form.Item
											label="User Email"
											style={{
												display: "flex",
												alignItems: "center",
												justifyContent: "center",
												margin: "0px",
											}}>
											{getFieldDecorator("email", {
												rules: [
													{
														required: true,
														message: "Please input your E-mail!",
													},
													{
														type: "email",
														message: "Please Enter Valid E-mail!",
													},
												],
												initialValue: checkValue(this.props.userProfile)
													? this.props.userProfile.email
													: "",
											})(
												this.state.userEnable ? (
													<Input
														prefix={
															<MailOutlined
																style={{ color: "rgba(0,0,0,.25)" }}
															/>
														}
														type="email"
														placeholder="User Email"
													/>
												) : (
													<Text>
														{checkValue(this.props.userProfile)
															? this.props.userProfile.email
															: "user email"}
													</Text>
												)
											)}
										</Form.Item>
										<Form.Item
											label="Communication Email"
											style={{
												display: "flex",
												alignItems: "center",
												justifyContent: "center",
												margin: "0px",
											}}>
											{getFieldDecorator("communication_email", {
												rules: [
													{
														// required: true,
														message: "Please Enter Your Communication Email!",
													},
													{
														type: "email",
														message: "Please Enter Valid Communication Email!",
													},
												],
												initialValue: checkValue(this.props.userProfile)
													? this.props.userProfile.communication_email
													: "",
											})(
												this.state.userEnable ? (
													<Input
														prefix={
															<MailOutlined
																style={{ color: "rgba(0,0,0,.25)" }}
															/>
														}
														type="email"
														placeholder="Communication Email"
													/>
												) : (
													<Text>
														{checkValue(this.props.userProfile)
															? this.props.userProfile.communication_email
															: "Communication Email"}
													</Text>
												)
											)}
										</Form.Item>
										<Form.Item
											label="User Contact"
											style={{
												display: "flex",
												alignItems: "center",
												justifyContent: "center",
												margin: "0px",
											}}>
											{this.state.userEnable ? (
												getFieldDecorator("ph_number", {
													rules: [
														{
															max: 16,
															min: 8,
															message: "Please input valid Contact Number!",
														},
													],
													initialValue: checkValue(this.props.userProfile)
														? this.props.userProfile.ph_number
														: "",
												})(<PhoneInput placeholder="Enter phone number" />)
											) : (
												<Text>
													{checkValue(this.props.userProfile)
														? this.props.userProfile.ph_number
														: "user contact"}
												</Text>
											)}
										</Form.Item>
										<Form.Item
											{...tailFormItemLayout}
											style={{
												display: "flex",
												alignItems: "center",
												justifyContent: "center",
												margin: "0px",
											}}>
											{this.state.userEnable ? (
												<>
													<Button
														type="primary"
														style={{ margin: "15px 16px" }}
														loading={this.props.userProfileStatus === LOADING}
														onClick={(e) => this.handleProfileUpdate(e)}>
														Save
													</Button>
													<Popconfirm
														title="Sure to cancel?"
														onConfirm={() =>
															this.setState({ userEnable: false })
														}>
														<Button type="default">Cancel</Button>
													</Popconfirm>
												</>
											) : (
												<>
													<Button
														type="primary"
														style={{
															margin: "15px 16px",
															// background: "#59c4af",
															// borderColor: "#59c4af",
															// width: "120px"
														}}
														onClick={() => this.setState({ userEnable: true })}>
														Edit
													</Button>
												</>
											)}
										</Form.Item>
									</Form>
								</Col>
								<Col span={12}>
									<Badge
										count="Letter Head"
										style={{ background: "rgb(89, 196, 175)" }}>
										<Tooltip
											placement="right"
											title="Click here to Upload/Change Letter Head">
											<Avatar
												size={180}
												shape="square"
												style={{
													border: "1px solid grey",
													width: "520px",
													height: "200px",
												}}
												className="avatar-logo"
												onClick={() =>
													this.changeStateValue_L1(
														"modalsVisibility",
														"uploadLogo",
														true
													)
												}>
												{this.props.userProfile ? (
													<img
														src={this.props?.userProfile?.letter_head_image}
														style={{ width: "100%" }}
														alt="user"
													/>
												) : (
													<UserOutlined />
												)}
											</Avatar>
										</Tooltip>
									</Badge>
								</Col>
							</Row>
						</Card>
						{window.location?.pathname?.split("/")[1] === "search" && (
							<Card
								title={disclaimerTitle}
								className="m-top-20"
								extra={
									this.state.disclaimerEnable ? (
										<>
											<Button
												type="primary"
												style={{ marginRight: "10px" }}
												onClick={(e) => this.handleProfileUpdate(e)}>
												Save
											</Button>
											<Popconfirm
												title="Sure to cancel?"
												onConfirm={() =>
													this.setState({ disclaimerEnable: false })
												}>
												<Button type="default">Cancel</Button>
											</Popconfirm>
										</>
									) : (
										<Button
											type="primary"
											onClick={() => this.setState({ disclaimerEnable: true })}>
											Edit
										</Button>
									)
								}>
								<Form>
									{/* Disclaimer Field */}
									<Form.Item>
										{getFieldDecorator("disclaimer", {
											rules: [],
											initialValue: checkValue(this.props.userProfile)
												? this.props.userProfile.disclaimer
												: "",
										})(
											this.state.disclaimerEnable ? (
												<CKEditor
													initData={this.state.editorValue}
													name="editor"
													onChange={(event)=>this.onEditorChange(event)}
													editorUrl={
														"https://cdn.ckeditor.com/4.16.0/full-all/ckeditor.js"
													}
													config={{
														allowedContent: true,
														extraAllowedContent: "div(*)",
														extraPlugins: ["editorplaceholder"],
														editorplaceholder: "Start typing here...",
														toolbarGroups: [
														  { name: "basicstyles", groups: ["basicstyles"] },
														  { name: "paragraph", groups: ["list", "align"] },
														  { name: "links" },
														  { name: "insert", groups: ["table"] },
														  { name: "styles" },
														  { name: "links" },
														],
														autoGrow_onStartup: true,
														height: "40vh",
														removePlugins: "flash,iframe",
													}}
												/>
											) : (
												<Text>
													{checkValue(this.props.userProfile?.disclaimer)
														? <div dangerouslySetInnerHTML={{ __html: this.props.userProfile.disclaimer }} />
														: "No disclaimer provided"}
												</Text>
											)
										)}
									</Form.Item>
								</Form>
							</Card>
						)}
					</Col>
				</Row>
				{/* profile-edit */}
				<UploadLetterHead
					visible={this.state.modalsVisibility.uploadLogo}
					onCloseModal={() =>
						this.changeStateValue_L1("modalsVisibility", "uploadLogo", false)
					}
				/>
			</div>
		);
  }
}

const mapDispatchToProps = {
  getProfile,
  editProfile,
};

function mapStateToProps(state) {
  const { userProfile, userProfileStatus, userProfileError } = state.profile;
  return deepFreeze({
    userProfile,
    userProfileStatus,
    userProfileError,
  });
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create({ name: "edit_profile" })(EditProfile));
