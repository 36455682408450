import React, { useState, useMemo, Suspense, useEffect } from "react";
import { Col, Row, Tabs, Typography } from "antd";
import { SettingTwoTone } from "@ant-design/icons";
import { checkValue } from "../../../functions/commonFunctions/checkValueFunctions";
import { useDispatch, useSelector } from "react-redux";
import { getDashboard } from "../../../redux/actions/managerActions/profile/actions";
import DeadlineSettingsComponent from "./commonComponents/DeadlineSettingsComponent";
import DeadlineDashboard from "./components/DeadlineDashboard";
import {
	deadline_filing_document_type,
	deadline_mm_type,
} from "./constants/index";
import {
	getDeadlineManagementData,
	getDeadlineManagementFilter,
    getDeadlineManagementRenewalData,
    getDeadlineManagementRenewalFilter,
} from "../../../redux/actions/managerActions/deadlineManagementDashboard";
import { default_filter_page_size } from "../../Watch/watchFilter/utils/utils";

const { Text, Title } = Typography;

const DeadlineManagement = () => {
	let loc = window.location.pathname.split("/");

	const { userSubscriptions } = useSelector((state) => state.userSubscriptions);

	const [activeTabKey, setActiveTabKey] = useState(
		loc.includes("your-trademark")
			? "formalities-chk-fail"
			: "filing-of-rule-45-evidence-affidavit"
	);
	const [trademarkStatus, setTrademarkStatus] = useState(
		loc.includes("your-trademark")
			? deadline_filing_document_type["FORMALITIES CHK FAIL"]
			: deadline_filing_document_type["FILING OF RULE 45 EVIDENCE AFFIDAVIT"]
	);
	const [openSettings, setOpenSettings] = useState(false);
	const [deadlineMMType, setDeadlineMMType] = useState(
		loc.includes("your-trademark")
			? deadline_mm_type["Portfolio trademark"]
			: deadline_mm_type["Third party Opposed trademark"]
	);
	const [dashboardType, setDashboardType] = useState(
		loc.includes("your-trademark")
			? "your_trademark"
			: "third_party_opposed_trademark"
	);
	// PSEUDO = CUSTOM
	const [changeSwitch, setChangeSwitch] = useState(
		userSubscriptions.manager.includes("PSEUDO_TRADEMARK") ? true : false
	);

	const [renewalChangeSwitch, setRenewalChangeSwitch] = useState(
		userSubscriptions.manager.includes("RENEWAL_PSEUDO_TRADEMARK") ? true : false
	);

	const [selected, setSelected] = React.useState([]);
	const [selectAll, setSelectAll] = React.useState(false);
	const [selectedRows, setSelectedRows] = useState([]);

	const { tmDashboard } = useSelector(
		(state) => state.managerState.profileState
	);

	const { config = {} } = useSelector(
		(state) =>
			state.managerState?.deadlineManagement?.deadlineFilters?.[
				dashboardType
			]?.[trademarkStatus] || {}
	);

	const {
		proprietorConfig,
		classConfig,
		trademarkTypeConfig,
		tagConfig,
		trademarkStatusConfig,
		trademarkStateConfig,
		trademarkOpponentConfig,
		trademarkAlertConfig,
		contains_term,
		dosDueDateConfig,
		douDueDateConfig,
		trademarkDateOfApplicationConfig,
		multiple_opponent_flag,
		show_all_opponents,
		serviced,
		upcoming_see_all_hearings,
        renewalDueDateConfig,
		validUptoDateConfig
	} = config;

	const { count } = useSelector(
		(state) =>
			state.managerState?.deadlineManagement?.dealineContent?.[dashboardType]?.[
				trademarkStatus
			]?.config || {}
	);

	const dispatch = useDispatch();

	useEffect(() => {
		if (localStorage.getItem("token")) {
			if (!tmDashboard) {
				dispatch(getDashboard("trademark", "trademark"));
			}
		}
	}, [tmDashboard]);

	const settingIcon = (
		<SettingTwoTone
			style={{ fontSize: "20px", paddingTop: "5px", cursor: "pointer" }}
			onClick={() => setOpenSettings(true)}
		/>
	);

	const pageTitleText = useMemo(() => {
		if (dashboardType === "your_trademark") {
			return changeSwitch
				? "Your Deadline Management (Custom)"
				: "Your Deadline Management";
		}
		if (dashboardType === "third_party_opposed_trademark") {
			return changeSwitch
				? "3rd Party Deadline Management (Custom)"
				: "3rd Party Deadline Management";
		}
		return "";
	}, [dashboardType, changeSwitch]);

	const trademarkCount = count ?? 0;

	const getSingleFilterData = (body) => {
		let newBody = {
			...body,
			pseudo_flag: changeSwitch,
			mm_type: deadlineMMType,
			upcoming: body?.hasOwnProperty("upcoming")
				? !body.upcoming
				: !upcoming_see_all_hearings,
			filing_document_type: trademarkStatus,
			page_size: default_filter_page_size,
			dashboardType: dashboardType,
		};
		if(body?.hasOwnProperty("changedPseudo")){
			newBody.pseudo_flag = body.changedPseudo;
		}
        if(activeTabKey === "renewal"){
            dispatch(getDeadlineManagementRenewalFilter(newBody))
        }else{
            dispatch(getDeadlineManagementFilter(newBody));
        }
	};

	const generateFilterObject = () => {
		let filter = {
			trademark_date_of_application:
				trademarkDateOfApplicationConfig?.value?.dateString ?? "",
			due_date_based_on_dou: douDueDateConfig?.value?.dateString ?? "",
			due_date_based_on_dos: dosDueDateConfig?.value?.dateString ?? "",
			trademark_class: classConfig?.onlyValues ?? [],
			trademark_state: trademarkStateConfig?.onlyValues ?? [],
			trademark_status: trademarkStatusConfig?.onlyValues ?? [],
			trademark_type: trademarkTypeConfig?.onlyValues ?? [],
			trademark_proprietor: proprietorConfig?.onlyValues ?? [],
			contains_term: contains_term ?? "",
			tags: tagConfig?.onlyValues ?? [],
			opponent: trademarkOpponentConfig?.onlyValues ?? [],
			alerts: trademarkAlertConfig?.onlyValues ?? [],
			no_tags: tagConfig?.noTags ?? false,
            serviced: serviced ?? false
		};

		if (dashboardType === "third_party_opposed_trademark") {
			filter.show_all_opponents = show_all_opponents ?? false
			filter.multiple_opponent_flag = multiple_opponent_flag ?? false
		}
        if(activeTabKey === "renewal"){
            filter.renewal_due_date = renewalDueDateConfig?.value?.dateString ?? "",
			filter.trademark_valid_upto = validUptoDateConfig?.value?.dateString ?? ""
            delete filter.opponent
            delete filter.due_date_based_on_dou
            delete filter.due_date_based_on_dos
            delete filter.serviced
        }
		return filter;
	};

	const isAnyFilterSelected = () => {
		const filter = generateFilterObject();

		const updatedFilter = {
			...filter,
		};

		return Object.values(updatedFilter).some((value) =>
			Array.isArray(value)
				? value.length > 0
				: typeof value === "boolean"
				? value
				: value !== ""
		);
	};

	const getIsFilterSelected = () => isAnyFilterSelected();

	const fetchDeadlineData = (body) => {
		let updatedBody = {
			...body,
			pseudo_flag: changeSwitch,
			mm_type: deadlineMMType,
			upcoming: body?.hasOwnProperty("upcoming")
				? !body.upcoming
				: !upcoming_see_all_hearings,
			filing_document_type: trademarkStatus,
			dashboardType: dashboardType,
		};
		if (body?.reset) {
			updatedBody.filter_flag = false;
			updatedBody.filters = {};
		} else {
			updatedBody.filter_flag = isAnyFilterSelected();
			updatedBody.filters = generateFilterObject();
		}
		if(body?.hasOwnProperty("changedPseudo")){
			updatedBody.pseudo_flag = body.changedPseudo;
		}
        if(activeTabKey === "renewal"){
            dispatch(getDeadlineManagementRenewalData(updatedBody))
        }else{
		    dispatch(getDeadlineManagementData(updatedBody));
        }
	};

	const pageTitle = (
		<Row>
			<Col span={12}>
				<Title
					style={{ marginBottom: "4px", display: "flex", alignItems: "center", verticalAlign:"center" }}
					level={3}>
					{pageTitleText} &nbsp;
					<span style={{fontSize:"16px",paddingTop:'8px',color:'grey'}}>{!upcoming_see_all_hearings ? "(Showing Upcoming Results)" : "(Showing All Results)"}</span> 
					&nbsp; {settingIcon}
				</Title>
				<Text style={{ fontSize: "16px" }} type="secondary">
					Total number of trademarks: <span style={{fontWeight:'bold'}}>{trademarkCount}</span>
				</Text>
			</Col>
			<Col span={12} className="alignR">
				<Text strong style={{ fontSize: "14px", color: "#5aac44" }}>
					*Information shown here is not more than{" "}
					{checkValue(tmDashboard) ? tmDashboard.trademark.days_old : 0} day(s)
					old.
				</Text>
			</Col>
		</Row>
	);

	const parentStatusFilter = (key) => {
		switch (key) {
			case "formalities-chk-fail":
				return deadline_filing_document_type["FORMALITIES CHK FAIL"];
			case "reply-to-examination-report":
				return deadline_filing_document_type["REPLY TO EXAMINATION REPORT"];
			case "filing-counter-statement":
				return deadline_filing_document_type["FILING COUNTER STATEMENT"];
			case "filing-of-rule-46-evidence-affidavit":
				return deadline_filing_document_type[
					"FILING OF RULE 46 EVIDENCE AFFIDAVIT"
				];
			case "renewal":
				return deadline_filing_document_type["RENEWAL"];
			case "filing-of-rule-45-evidence-affidavit":
				return deadline_filing_document_type[
					"FILING OF RULE 45 EVIDENCE AFFIDAVIT"
				];
			case "filing-of-rule-47-evidence-affidavit":
				return deadline_filing_document_type[
					"FILING OF RULE 47 EVIDENCE AFFIDAVIT"
				];
			default:
				return "";
		}
	};

	const tabProps = {
		filing_document_type: trademarkStatus,
		changeSwitch: changeSwitch,
		deadlineMMType: deadlineMMType,
		dashboardType: dashboardType,
		getSingleFilterData: getSingleFilterData,
		selected,
		setSelected,
		selectAll,
		setSelectAll,
		selectedRows,
		setSelectedRows,
		fetchDeadlineData,
		getIsFilterSelected,
        generateFilterObject,
		renewalChangeSwitch,
		setRenewalChangeSwitch
	};

	const memoizedComponents = useMemo(() => {
		const tabKeys = [
			"formalities-chk-fail",
			"reply-to-examination-report",
			"filing-counter-statement",
			"filing-of-rule-46-evidence-affidavit",
			"renewal",
			"filing-of-rule-45-evidence-affidavit",
			"filing-of-rule-47-evidence-affidavit",
		];

		return tabKeys.reduce((acc, key) => {
			acc[key] = (<DeadlineDashboard {...tabProps} />);
			return acc;
		}, {});
	}, [activeTabKey, getIsFilterSelected]);

	const yourTrademarkTabItems = [
		{
			key: "formalities-chk-fail",
			label: "Formalities Chk Fail",
			children: memoizedComponents["formalities-chk-fail"],
		},
		{
			key: "reply-to-examination-report",
			label: "Reply To Examination Report",
			children: memoizedComponents["reply-to-examination-report"],
		},
		{
			key: "filing-counter-statement",
			label: "Filing Counter Statement",
			children: memoizedComponents["filing-counter-statement"],
		},
		{
			key: "filing-of-rule-46-evidence-affidavit",
			label: "Filing of Rule 46 Evidence Affidavit",
			children: memoizedComponents["filing-of-rule-46-evidence-affidavit"],
		},
		{
			key: "renewal",
			label: "Renewal",
			children: memoizedComponents["renewal"],
		},
	];

	const thirdPartyTrademarkTabItems = [
		{
			key: "filing-of-rule-45-evidence-affidavit",
			label: "Filing of Rule 45 Evidence Affidavit",
			children: memoizedComponents["filing-of-rule-45-evidence-affidavit"],
		},
		{
			key: "filing-of-rule-47-evidence-affidavit",
			label: "Filing of Rule 47 Evidence Affidavit",
			children: memoizedComponents["filing-of-rule-47-evidence-affidavit"],
		},
	];

	const tabItems =
		dashboardType === "your_trademark"
			? yourTrademarkTabItems
			: thirdPartyTrademarkTabItems;

	const handleTabChange = (activeKey) => {
		setActiveTabKey(activeKey);
		setTrademarkStatus(parentStatusFilter(activeKey));
		if(activeKey === "renewal") {
			setChangeSwitch(renewalChangeSwitch);
		}else{
			setChangeSwitch(userSubscriptions.manager.includes("PSEUDO_TRADEMARK") ? true : false);
		}
		setSelectAll(false)
		setSelected([])
		setSelectedRows([])
	};

	const tabSection = () => (
		<Tabs
			defaultActiveKey={activeTabKey}
			items={tabItems}
			onChange={handleTabChange}
			destroyInactiveTabPane={true}
		/>
	);

	return (
		<div style={{ padding: "10px", height: "100%" }}>
			{openSettings && (
				<DeadlineSettingsComponent
					open={openSettings}
					setOpen={setOpenSettings}
					mm_type={deadlineMMType}
				/>
			)}
			{pageTitle}
			<Suspense>{tabSection()}</Suspense>
		</div>
	);
};

export default DeadlineManagement;
