import { Button, Col, Modal, Row } from "antd";
import React from "react";

const CommonInfoModal = (props) => {
	const {
		open,
		setOpen,
		loading,
		title = "",
		cancelButtonText,
		saveButtonText,
		cancelButtonExtraAction,
		saveButtonExtraAction,
		infoText,
	} = props;

	const onClose = () => {
		setOpen?.(false);
		cancelButtonExtraAction?.();
	};

	const handleSubmit = () => {
		saveButtonExtraAction?.();
	};

	return (
			<Modal
				title={title ? title : "Note"}
				centered
				open={open}
				onCancel={loading ? null : onClose}
				footer={[
					<Button
						key="cancel"
						disabled={loading}
						type="default"
						onClick={onClose}>
						{cancelButtonText ?? "Cancel"}
					</Button>,
					<Button
						loading={loading}
						key="submit"
						type="primary"
						disabled={loading}
						onClick={() => handleSubmit()}>
						{saveButtonText ?? "Save"}
					</Button>,
				]}
				bodyStyle={{ maxHeight: "80vh", overflowY: "auto" }}
				width={500}
				closable={!loading}>
					<Row>
						<Col span={24}>{infoText ?? ""}</Col>
					</Row>
			</Modal>
	);
};

export default CommonInfoModal;
