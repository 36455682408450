import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deadline_mm_type } from "../../constants";
import { getTrademarkDeadlineManagementData } from "../../../../../redux/actions/managerActions/deadlineManagementDashboard";
import { Card, Switch } from "antd";
import DeadlineContent from "./DeadlineContent";

const TrademarkProfileDeadline = ({
	trademarkType: dashboardType,
	management_mark_id,
}) => {
	const dispatch = useDispatch();
	const { userSubscriptions } = useSelector((state) => state.userSubscriptions);

	const allDeadlineTypes = useSelector(
		(state) =>
			state.managerState?.deadlineManagement?.deadlineTrademark?.[
				dashboardType
			] ?? {}
	);

	const normalPseudo = userSubscriptions.manager.includes("PSEUDO_TRADEMARK");
	const [renewalChangeSwitch, setRenewalChangeSwitch] = useState(
		userSubscriptions.manager.includes("RENEWAL_PSEUDO_TRADEMARK")
	);

	const deadlineMMType =
		dashboardType === "your_trademark"
			? deadline_mm_type["Portfolio trademark"]
			: deadline_mm_type["Third party Opposed trademark"];

	const fetchSingleDeadline = (body) => {
		let updatedBody = {
			pseudo_flag: normalPseudo,
			mm_type: deadlineMMType,
			upcoming: false,
			dashboardType,
			filter_flag: false,
			filters: {},
			management_mark_id,
			isRenewal: false,
			...body,
		};
		dispatch(getTrademarkDeadlineManagementData(updatedBody));
	};

	const fetchAllDeadlines = () => {
		let deadlines = [];

		if (dashboardType === "your_trademark") {
			deadlines = [
				"FORMALITIES CHK FAIL",
				"REPLY TO EXAMINATION REPORT",
				"FILING COUNTER STATEMENT",
				"FILING OF RULE 46 EVIDENCE AFFIDAVIT",
				"RENEWAL",
			];
		} else if (dashboardType === "third_party_opposed_trademark") {
			deadlines = [
				"FILING OF RULE 45 EVIDENCE AFFIDAVIT",
				"FILING OF RULE 47 EVIDENCE AFFIDAVIT",
			];
		}

		deadlines.forEach((type) => {
			const isRenewal = type === "RENEWAL";
			const pseudo_flag = isRenewal ? renewalChangeSwitch : normalPseudo;

			fetchSingleDeadline({
				pseudo_flag,
				filing_document_type: type,
				isRenewal,
			});
		});
	};

	const handleChangeSwitch = (checked) => {
		setRenewalChangeSwitch(checked);
		// data api call
		let bodyForPseudo = {
			pseudo_flag: checked,
			isRenewal: true,
			filing_document_type: "RENEWAL"
		};
		fetchSingleDeadline(bodyForPseudo);
	}

	useEffect(() => {
		fetchAllDeadlines();
	}, []);

	const cardTitle = (label, count = 0) => {
		return (
			<>
				{label}&nbsp;
				<span style={{ color: "#181818", opacity: "80%" }}>({count})</span>
			</>
		);
	};

	const renderDeadlineComponent = () => {
		return Object.entries(allDeadlineTypes).map(([key, item]) => {
			return (
				<div style={{ marginBottom: "20px" }}>
					<Card
						title={cardTitle(key, item?.config?.count ?? 0)}
						extra={(key === "RENEWAL" && userSubscriptions.manager.includes("PSEUDO_TRADEMARK")) ? (
							<div
								style={{
									display: "flex",
									alignItems: "center",
									flexWrap: "wrap",
									gap: "8px",
								}}>
								<span style={!renewalChangeSwitch ?{ fontWeight: 600 }:{}}>IP INDIA</span>
								<Switch
									className="change-manager-type"
									onChange={(event) => handleChangeSwitch(event)}
									checked={renewalChangeSwitch}
								/>
								<span style={renewalChangeSwitch ?{ fontWeight: 600 }:{}}>CUSTOM</span>
							</div>
						): false}
						headStyle={{ background: "#ebf4ff" }}
						loading={item?.config?.loading}
						bodyStyle={{padding: "0px 20px"}}
						>
						<DeadlineContent
							key={key}
							config={item.config}
							filing_document_type={key}
							dashboardType={dashboardType}
							finalChangeSwitch={key === "RENEWAL" ? renewalChangeSwitch : normalPseudo}
							deadlineMMType={deadlineMMType}
							fetchSingleDeadline={fetchSingleDeadline}
						/>
					</Card>
				</div>
			);
		});
	};

	return <>{renderDeadlineComponent()}</>;
};

export default TrademarkProfileDeadline;
