import React from "react";
import DeadlineFilters from "./DeadlineFilters";
import DeadlineContent from "./DeadlineContent";

const DeadlineDashboard = (props) => {
	const {
		filing_document_type,
		changeSwitch,
		deadlineMMType,
		dashboardType,
		getSingleFilterData,
		selected,
		setSelected,
		selectAll,
		setSelectAll,
		selectedRows,
		setSelectedRows,
        fetchDeadlineData,
        getIsFilterSelected,
        generateFilterObject,
		renewalChangeSwitch, 
		setRenewalChangeSwitch
	} = props;

	const commonProps = {
		filing_document_type: filing_document_type,
		changeSwitch: changeSwitch,
		deadlineMMType: deadlineMMType,
		getSingleFilterData: getSingleFilterData,
		dashboardType: dashboardType,
        fetchDeadlineData: fetchDeadlineData,
        selected,
        setSelected,
		selectAll,
		setSelectAll,
		setSelectedRows,
        selectedRows,
        getIsFilterSelected,
        generateFilterObject
	};

	const propsForFilters = {
		...commonProps,
		renewalChangeSwitch,
		setRenewalChangeSwitch
	};

	const propsForContent = {
		...commonProps,
	};

	const filters = <DeadlineFilters {...propsForFilters} />;
	const content = <DeadlineContent {...propsForContent} />;

	return (
		<div style={{ display: "flex", flexDirection: "column" }}>
			{filters}
			{content}
		</div>
	);
};

export default DeadlineDashboard;
