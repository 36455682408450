import {
	ERROR,
	LOADING,
	SUCCESS,
} from "../../../../constants/commonConstants/generalConstants";
import { GET_DM_LIST_DATA, GET_DM_RENEWAL_LIST_DATA } from "./types";

const defaultFilterConfig = {
	tableData: [],
	isTableDataPresent: false,
	count: null,
	pageNumber: 1,
	report_columns: [],
	loading: false,
};

const initialState = {
	your_trademark: {
		"FORMALITIES CHK FAIL": {
			config: defaultFilterConfig,
			isComponentRendered: false,
		},
		"REPLY TO EXAMINATION REPORT": {
			config: defaultFilterConfig,
			isComponentRendered: false,
		},
		"FILING COUNTER STATEMENT": {
			config: defaultFilterConfig,
			isComponentRendered: false,
		},
		"FILING OF RULE 46 EVIDENCE AFFIDAVIT": {
			config: defaultFilterConfig,
			isComponentRendered: false,
		},
		RENEWAL: {
			config: defaultFilterConfig,
			isComponentRendered: false,
		},
	},
	third_party_opposed_trademark: {
		"FILING OF RULE 45 EVIDENCE AFFIDAVIT": {
			config: defaultFilterConfig,
			isComponentRendered: false,
		},
		"FILING OF RULE 47 EVIDENCE AFFIDAVIT": {
			config: defaultFilterConfig,
			isComponentRendered: false,
		},
	},
};

const updateConfig = (state, payload, changes, isComponentRendered) => {
	const { dashboardType, filing_document_type } = payload;
	return {
		...state,
		[dashboardType]: {
			...state[dashboardType],
			[filing_document_type]: {
				...state[dashboardType][filing_document_type],
				config: {
					...state[dashboardType][filing_document_type].config,
					...changes,
				},
				isComponentRendered,
			},
		},
	};
};

export default function deadlineManagementContent(
	state = initialState,
	action
) {
	switch (action.type) {
		case GET_DM_LIST_DATA:
		case GET_DM_RENEWAL_LIST_DATA: {
			const { payload, status } = action;
			const { filing_document_type, dashboardType, data, page } = payload;

			if (!state[dashboardType]?.[filing_document_type]) {
				return state;
			}

			const updatedData =
				status === SUCCESS
					? data.data
					: state[dashboardType][filing_document_type].config.tableData;
			return updateConfig(
				state,
				payload,
				{
					tableData: updatedData,
					isTableDataPresent: updatedData?.length > 0,
					count: status === ERROR ? 0 : data?.metadata?.count ?? 0,
					pageNumber: status === SUCCESS ? page ?? 1 : 1,
					report_columns: data?.metadata?.report_columns ?? [],
					loading: status === LOADING ? true : false,
				},
				status === SUCCESS
			);
		}

		default:
			return state;
	}
}
