import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row, Table, Image, notification, Tooltip, Modal } from "antd";
import { checkValue } from "../../../../functions/commonFunctions/checkValueFunctions";
import {
	CarryOutTwoTone,
	CheckCircleTwoTone,
	FilterFilled,
	MessageTwoTone,
	UserAddOutlined,
	UserDeleteOutlined,
} from "@ant-design/icons";
import SelectContainer from "../commonComponents/filterContainer.jsx/SelectContainer";
import SingleDateFilterContainer from "../commonComponents/filterContainer.jsx/SingleDateFilterContainer";
import CreateTask from "../../to-do-task/CreateTask";
import ManagerNotes from "../../subComponents/Modal/managerNotes";
import {
	PATCH_TM_NOTES,
	POST_TM_NOTES,
} from "../../../../redux/actions/managerActions/commonAction/types";
import { TM } from "./../../../../constants/managerConstants/managerConstants";
import { getEmailTemplateData } from "../../../../redux/actions/commonActions/actions";
import SendEmailNew from "./../../../commonComponents/Modals/SendEmailNew";
import { updateDeadlineManagementRenewalReplyFiled, updateDeadlineManagementReplyFiledOrNotMyClient } from "../../../../redux/actions/managerActions/deadlineManagementDashboard";
import NotMyClientComponent from "../commonComponents/modalComponents/NotMyClientComponent";
import NewReplyFiled from "../commonComponents/modalComponents/NewReplyFiled";
import {
	allowed_opponent_column,
	allowed_reason_for_notice,
} from "../constants";

const DeadlineContent = (props) => {
	const {
		changeSwitch,
		deadlineMMType,
		filing_document_type,
		dashboardType,
		selected,
		setSelected,
		selectAll,
		setSelectAll,
		selectedRows,
		setSelectedRows,
		getSingleFilterData,
		fetchDeadlineData,
		getIsFilterSelected,
		generateFilterObject,
	} = props;

	const [addTaskVisible, setAddTaskVisible] = useState(false);
	const [selectedApplicationNumber, setSelectedApplicationNumber] =
		useState("");
	const [managerNotes, setManagerNotes] = useState(false);
	const [mailObj, setMailObj] = useState({ visible: false });

	const [openReplyFiled, setOpenReplyFiled] = useState(false);
	const [replyFiledloading, setReplyFiledLoading] = useState(false);
	const [openNotMyClient, setOpenNotMyClient] = useState(false);
	const [notMyClientLoading, setNotMyClientLoading] = useState(false);
	const [selectedData, setSelectedData] = useState({});

	const actionIconSize = "20px";
	const actionIconColor = "#1890ff";

	const dispatch = useDispatch();

	const { userDetails, userTeam } = useSelector(
		(state) => state.userSubscriptions
	);

	const { config = {}, isComponentRendered } = useSelector(
		(state) =>
			state.managerState?.deadlineManagement?.dealineContent?.[dashboardType]?.[
				filing_document_type
			] || {}
	);

	const { count, loading, pageNumber, tableData } = config;

	const { config: filterConfig = {} } = useSelector(
		(state) =>
			state.managerState?.deadlineManagement?.deadlineFilters?.[
				dashboardType
			]?.[filing_document_type] || {}
	);

	const {
		classConfig = {},
		tagConfig = {},
		trademarkOpponentConfig = {},
		trademarkAlertConfig = {},
		trademarkDateOfApplicationConfig = {},
		douDueDateConfig = {},
		dosDueDateConfig = {},
		trademarkStatusConfig = {},
		renewalDueDateConfig = {},
		proprietorConfig = {},
        upcoming_see_all_hearings,
		validUptoDateConfig
	} = filterConfig;

	const handleFetchDeadlineData = () => {
		let body = {
			page: 1,
		};
		fetchDeadlineData?.(body);
	};

	useEffect(() => {
        if(!isComponentRendered){
            handleFetchDeadlineData();
        }
	}, []);

	const handleManagerNotes = (data) => {
		setManagerNotes(true);
		setSelectedApplicationNumber(data.application_number);
	};

	const handleNextpage = (pageNumber) => {
		let body = {
			page: pageNumber,
		};
		fetchDeadlineData?.(body);
	};

	const handleTrademarkProfile = (data) => {
		if (data) {
			sessionStorage.setItem(
				"management_trademark_id",
				data.management_mark_id
			);
			sessionStorage.setItem(
				"trademark_application_number",
				data.application_number
			);
			if(dashboardType === "third_party_opposed_trademark"){
				window.open(
					"/manager/trademark/opposed-trademark/trademark-profile/" + data.management_mark_id,
					"_blank"
				);
			}else{
				window.open(
					"/manager/trademark/trademark-profile/" + data.management_mark_id,
					"_blank"
				);
			}
		}
	};

	const handleProprietorProfile = (data) => {
		if (data) {
			sessionStorage.setItem("proprietor_id", data.id);
			window.open("/manager/trademark/proprietor-profile/" + data.id, "_blank");
			this.setState({
				proprietor_id: data.id,
			});
		}
	};

	const onSelectChange = (record, selected) => {
		if (selected) {
			let newSelected = [...selectedRows, record];
			setSelected(newSelected.map((x) => x.id));
			setSelectedRows(newSelected);
		} else {
			let newSelected = selectedRows.filter((x) => x.id !== record.id);

			setSelected(newSelected.map((x) => x.id));
			setSelectedRows(newSelected);
		}
	};

	const rowSelection = {
		renderCell: function (checked, record, index, originNode) {
			return (
				<div
					className="tmapplied_selection_cols"
					style={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
					}}>
					<div
						style={{
							display: "flex",
							alignItems: "center",
						}}>
						<div style={{ margin: "4px" }}>
							{" "}
							{index + 1 + (pageNumber - 1) * 100}.{" "}
						</div>
						<div> {originNode}</div>
					</div>
				</div>
			);
		},
		selectedRowKeys: selected,
		onSelect: onSelectChange,
		selections: null,
		onSelectAll: (selected, selectedRows) => {
			setSelectAll(selected);
			setSelected(selectedRows);
			if (selected) {
				notification.open({
					message: "Deselect All Mark",
					description:
						"Click on the arrow near the select all checkbox to deselect all marks",
				});
			}
		},
		selections: selectAll
			? [
					{
						key: "all-data",
						text: "Deselect All Data",
						onSelect: (changeableRowKeys) => {
							setSelectAll(false);
							setSelected([]);
							setSelectedRows([]);
						},
					},
			  ]
			: false,
		getCheckboxProps: (record) => ({
			disabled: selectAll,
		}),
	};

	const reasonShow = (data) => {
		Modal.info({
			title: "Reason for Notice",
			width: "50%",
			content: (
				<div>
					<p>{data}</p>
				</div>
			),
		});
	};

	const handlePdfClick = (data) => {
		let url = "";
		if (data?.file) {
			url = data?.file;
		} else {
			url = data?.url;
		}
		if (url !== null) window.open(url, "_blank");
	};

	const handleEmail = async (data) => {
		let mailContent = "<p><strong>Trademark Manager</strong></p>";
		setMailObj({
			...mailObj,
			visible: true,
			mailContent: mailContent,
			product: "MANAGER",
			subscription: [changeSwitch ? "PSEUDO_TRADEMARK" : "TRADEMARK"],
			report_tag: {
				journal_number: checkValue(data.journal) ? data.journal.number : "",
				journal_date: checkValue(data.journal)
					? data.journal.date_of_publication
					: "",
				status: checkValue(data.status) ? data.status : "",
				renewal_date: checkValue(data.valid_upto) ? data.valid_upto : "",
				application_number: checkValue(data.application_number)
					? data.application_number
					: "",
				mark_name: checkValue(data.applied_for) ? data.applied_for : "",
				date_of_application: checkValue(data.application_date)
					? data.application_date
					: "",
				class: checkValue(data.associated_class)
					? data.associated_class.join(", ")
					: "",
				date_of_usage: checkValue(data.date_of_usage) ? data.date_of_usage : "",
				proprietor_name: checkValue(data.proprietor)
					? data.proprietor.map((prop) => prop.name).join(", ")
					: "",
				opponent_name: checkValue(data.opponent_name) ? data.opponent_name : "",
				tla_hearing_date: "",
				opposition_hearing_date: "",
				report_name: "",
			},
			objectType: changeSwitch ? "pseudo_trademark" : "trademark",
			applicationNumber: data.application_number,
			markId: data.management_mark_id,
			sourceId: data.management_mark_id,
		});
		dispatch(
			getEmailTemplateData({
				subscription: "TRADEMARK",
				sub_section: "DASHBOARD",
				product: "MANAGER",
				object_id: data?.application_number,
			})
		);
	};

	const generateReplyFiledAndSetNotMyOpponent = (body) => {
		const updatedBody = {
			pseudo_flag: changeSwitch,
			mm_type: deadlineMMType,
			filing_document_type: filing_document_type,
			is_reply_filed: body?.is_reply_filed ?? false,
			is_not_my_opponent: body?.is_not_my_opponent ?? false,
			selected_ids: [selectedData.id],
			filter_flag: false,
			filters: {},
			upcoming: !upcoming_see_all_hearings,
			select_all: selectAll,
		};
		if(selectAll){
			updatedBody.filter_flag= getIsFilterSelected()
            updatedBody.filters = generateFilterObject()
		}

		const handleSuccess = () => {
			fetchDeadlineData({ page: pageNumber });
			setReplyFiledLoading(false);
			setNotMyClientLoading(false);
			setOpenReplyFiled(false);
			setOpenNotMyClient(false);
		};

		const handleError = (errorResponse) => {
			notification.error({
				message: errorResponse?.message ?? "Something went wrong!",
			});
			setReplyFiledLoading(false);
			setNotMyClientLoading(false);
		};

		const commonDispatchProps = {
			body: updatedBody,
			onLoading: () => {
				setReplyFiledLoading(true);
				setNotMyClientLoading(true);
			},
			onSuccess: handleSuccess,
			onError: handleError,
		};

		if (filing_document_type === "RENEWAL") {
			delete updatedBody.is_not_my_opponent;
			delete updatedBody.mm_type;
			delete updatedBody.filing_document_type;
			dispatch(updateDeadlineManagementRenewalReplyFiled(commonDispatchProps));
		} else {
			dispatch(
				updateDeadlineManagementReplyFiledOrNotMyClient(commonDispatchProps)
			);
		}
	};

	const handleReplyFiled = (item) => {
		setSelectedData(item);
		setOpenReplyFiled(true);
	};

	const handleNotMyClient = (item) => {
		setSelectedData(item);
		setOpenNotMyClient(true);
	};

	const getColumnFilter = (dataIndex) => ({
		filterIcon: () => {
			let filtered = false;
			filtered =
				(dataIndex === "classes" && classConfig?.onlyValues?.length) ||
				(dataIndex === "opponent" &&
					trademarkOpponentConfig?.onlyValues?.length) ||
				(dataIndex === "alert" && trademarkAlertConfig?.onlyValues?.length) ||
				(dataIndex === "tags" &&
					(tagConfig?.onlyValues?.length || tagConfig?.noTags)) ||
				(dataIndex === "trademark_date_of_application" &&
					!!trademarkDateOfApplicationConfig?.value?.dateString) ||
				(dataIndex === "dou_due_date" &&
					!!douDueDateConfig?.value?.dateString) ||
				(dataIndex === "dos_due_date" &&
					!!dosDueDateConfig?.value?.dateString) ||
				(dataIndex === "status" && trademarkStatusConfig?.onlyValues?.length) ||
				(dataIndex === "renewal_due_date" &&
					!!renewalDueDateConfig?.value?.dateString) ||
				(dataIndex === "valid_upto_date" &&
					!!validUptoDateConfig?.value?.dateString) ||
				(dataIndex === "proprietor" && proprietorConfig?.onlyValues?.length);

			return (
				<FilterFilled
					id={`watch_table_filter_icon_id_${dataIndex}`}
					style={{
						color: filtered ? "#1677ff" : "black",
						marginRight: dataIndex === "threat_proprietor" ? "4px" : "",
					}}
				/>
			);
		},
		filterDropdown: ({
			setSelectedKeys,
			selectedKeys,
			confirm,
			clearFilters,
			visible,
		}) => (
			<>
				{dataIndex === "classes" && visible && (
					<SelectContainer
						filteredData={classConfig}
						key={classConfig.key}
						loading={classConfig.loading}
						handleGetSingleFilter={getSingleFilterData}
						filing_document_type={filing_document_type}
						dashboardType={dashboardType}
						tableView={true}
					/>
				)}
				{dataIndex === "status" && visible && (
					<SelectContainer
						filteredData={trademarkStatusConfig}
						key={trademarkStatusConfig.key}
						loading={trademarkStatusConfig.loading}
						handleGetSingleFilter={getSingleFilterData}
						filing_document_type={filing_document_type}
						dashboardType={dashboardType}
						tableView={true}
					/>
				)}
				{dataIndex === "opponent" && visible && (
					<SelectContainer
						filteredData={trademarkOpponentConfig}
						key={trademarkOpponentConfig.key}
						loading={trademarkOpponentConfig.loading}
						handleGetSingleFilter={getSingleFilterData}
						filing_document_type={filing_document_type}
						dashboardType={dashboardType}
						tableView={true}
					/>
				)}
				{dataIndex === "tags" && visible && (
					<SelectContainer
						filteredData={tagConfig}
						key={tagConfig.key}
						loading={tagConfig.loading}
						handleGetSingleFilter={getSingleFilterData}
						filing_document_type={filing_document_type}
						dashboardType={dashboardType}
						tableView={true}
						showTagIcon={true}
					/>
				)}
				{dataIndex === "alert" && visible && (
					<SelectContainer
						filteredData={trademarkAlertConfig}
						key={trademarkAlertConfig.key}
						loading={trademarkAlertConfig.loading}
						handleGetSingleFilter={getSingleFilterData}
						filing_document_type={filing_document_type}
						dashboardType={dashboardType}
						tableView={true}
					/>
				)}

				{dataIndex === "trademark_date_of_application" && visible && (
					<SingleDateFilterContainer
						menu_label={"Date of Application"}
						subtitle1={"Date of Application"}
						key={"deadline_trademark_date_of_application"}
						filter_key_1="trademark_date_of_application"
						value1={trademarkDateOfApplicationConfig.value}
						tableView
						filing_document_type={filing_document_type}
						dashboardType={dashboardType}
					/>
				)}

				{dataIndex === "dou_due_date" && visible && (
					<SingleDateFilterContainer
						menu_label={"Upload Due Date"}
						subtitle1={"Upload Due Date"}
						key={"deadline_dou_due_date"}
						filter_key_1="dou_due_date"
						value1={douDueDateConfig.value}
						tableView
						filing_document_type={filing_document_type}
						dashboardType={dashboardType}
					/>
				)}

				{dataIndex === "dos_due_date" && visible && (
					<SingleDateFilterContainer
						menu_label={"Service Due Date"}
						subtitle1={"Service Due Date"}
						key={"deadline_dos_due_date"}
						filter_key_1="dos_due_date"
						value1={dosDueDateConfig.value}
						tableView
						filing_document_type={filing_document_type}
						dashboardType={dashboardType}
					/>
				)}
				{dataIndex === "renewal_due_date" && visible && (
					<SingleDateFilterContainer
						menu_label={"Due Date"}
						subtitle1={"Renewal Date"}
						key={"deadline_renewal_due_date"}
						filter_key_1="renewal_due_date"
						value1={renewalDueDateConfig.value}
						tableView
						filing_document_type={filing_document_type}
						dashboardType={dashboardType}
					/>
				)}

				{dataIndex === "proprietor" && visible && (
					<SelectContainer
						filteredData={proprietorConfig}
						key={proprietorConfig.key}
						loading={proprietorConfig.loading}
						handleGetSingleFilter={getSingleFilterData}
						filing_document_type={filing_document_type}
						dashboardType={dashboardType}
						tableView={true}
					/>
				)}

				{dataIndex === "valid_upto_date" && visible && (
					<SingleDateFilterContainer
						menu_label={"Valid Upto Date"}
						subtitle1={"Valid Upto"}
						key={"deadline_valid_upto_date"}
						filter_key_1="valid_upto_date"
						value1={validUptoDateConfig.value}
						tableView
						filing_document_type={filing_document_type}
						dashboardType={dashboardType}
					/>
				)}
			</>
		),
		onFilter: (value, record) =>
			record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
	});

	const showOpponentColumn =
		allowed_opponent_column.includes(filing_document_type);

	const showReasonForNoticeColumn =
		allowed_reason_for_notice.includes(filing_document_type);

	const columns = [
		{
			title: "TRADEMARK",
			className: "tm-protect-column-1",
			render: (text, record) => {
				return (
					<div>
						<p
							className="application-text"
							onClick={() => handleTrademarkProfile(record)}>
							<span>
								{checkValue(record.application_number)
									? record.application_number
									: "N.A."}{" "}
								-{" "}
							</span>
							<span className="text-camelCase">
								{checkValue(record.applied_for)
									? record.applied_for.toLowerCase()
									: "N.A."}
							</span>
						</p>
						<p>
							<Image
								className="trademark-image"
								src={
									checkValue(record.associated_image)
										? record.associated_image
										: ""
								}
								alt=""
							/>
						</p>
					</div>
				);
			},
		},
		{
			title: "CLASS",
			className: "tm-protect-column-1",
			render: (text, record) => {
				return (
					<div>
						<p className="text-camelCase">
							{checkValue(record.associated_class)
								? record.associated_class.join(", ")
								: "N.A."}
						</p>
					</div>
				);
			},
			...getColumnFilter("classes"),
		},
		{
			title: "STATUS ",
			className: "tm-protect-column-1",
			render: (text, record) => {
				return (
					<div>
						<p className="text-camelCase">
							{checkValue(record.status) ? record.status.toLowerCase() : "N.A."}
						</p>
					</div>
				);
			},
			...getColumnFilter("status"),
		},
		{
			title: "DOA",
			className: "tm-protect-column-1",
			render: (text, record) => {
				return (
					<div>
						<p className="text-camelCase">
							{checkValue(record.application_date)
								? record.application_date
								: "N.A."}
						</p>
					</div>
				);
			},
			...getColumnFilter("trademark_date_of_application"),
		},
		{
			title: "PROPRIETOR",
			dataIndex: "proprietor",
			className: "trademark-column",
			render: (proprietor) => {
				return (
					<div>
						{proprietor?.length ? (
							proprietor?.map((proprietorData, proprietorKey) => (
								<p
									key={proprietorKey}
									className="application-text text-camelCase"
									onClick={() => handleProprietorProfile(proprietorData)}>
									{checkValue(proprietorData.name)
										? proprietorData.name.toLowerCase()
										: "N.A."}
								</p>
							))
						) : (
							<p className="text-camelCase" style={{ textAlign: "center" }}>
								N.A.
							</p>
						)}
					</div>
				);
			},
			...getColumnFilter("proprietor"),
		},
		...(showOpponentColumn
			? [
					{
						title: "OPPONENT NAME | OPPONENT NUMBER",
						className: "tm-protect-column-1",
						render: (text, record) => {
							return (
								<div>
									<span
										className="text-camelCase"
										style={
											dashboardType === "third_party_opposed_trademark" &&
											record?.is_not_my_opponent
												? { color: "red" }
												: {}
										}>
										{checkValue(record.opponent_name)
											? record.opponent_name.toLowerCase()
											: "N.A."}
									</span>
									{record.opponent_number && (
										<p>
											(
											{checkValue(record.opponent_number)
												? record.opponent_number
												: "N.A."}
											)
										</p>
									)}
								</div>
							);
						},
						...getColumnFilter("opponent"),
					},
			  ]
			: []),
		...(showReasonForNoticeColumn
			? [
					{
						title: "REASON FOR NOTICE",
						className: "tm-protect-column-1",
						render: (text, record) => {
							return (
								<div>
									{record?.correspondence_notices &&
										record?.correspondence_notices?.length > 0 &&
										record?.correspondence_notices?.map((doc) =>
											checkValue(doc?.reasons) ? (
												<Tooltip title="Click here to show reason">
													<p
														className="application-text alignC"
														onClick={() => reasonShow(doc.reasons)}>
														Show Reason
													</p>
												</Tooltip>
											) : (
												<p>N.A</p>
											)
										)}
									{record?.correspondence_notices?.length === 0 && <p>N.A.</p>}
								</div>
							);
						},
					},
			  ]
			: []),
		...(filing_document_type !== "RENEWAL"
			? [
					{
						title: "DOCUMENT | TIME REMAINING",
						className: "tm-protect-column-1",
						render: (text, record) => {
							return (
								<div>
									{record.documents &&
										record.documents?.length > 0 &&
										record.documents?.map((doc, index) => (
											<p key={index} className="h_80px vertical_scroll">
												<span
													onClick={() => handlePdfClick(doc)}
													className="application-text">
													{doc?.description}
												</span>
												<p>({checkValue(doc?.date) ? doc?.date : "N.A."})</p>
												<div>
													<span>{doc?.remaining_days} Days</span>
													<span
														className={doc?.alert ? "visible" : "not-visible"}>
														<Tooltip
															title="Deadline to file reply for this mark has passed"
															className="toolTip-custom">
															<i
																className="fa fa-exclamation-circle"
																aria-hidden="true"
																style={{
																	color: "#e1001b",
																	paddingLeft: "5px",
																	cursor: "pointer",
																}}></i>
														</Tooltip>
													</span>
												</div>
											</p>
										))}
									{dashboardType === "your_trademark" &&
										record?.status === "Formalities Chk Fail" &&
										record.correspondence_notices &&
										record.correspondence_notices?.length > 0 &&
										record.correspondence_notices.map((doc) => (
											<p className="h_80px vertical_scroll">
												<div>
													<span
														onClick={() => handlePdfClick(doc)}
														className="application-text">
														{doc?.subject}
													</span>
													<p>
														(
														{checkValue(doc?.date_of_correspondence)
															? doc?.date_of_correspondence
															: "N.A."}
														)
													</p>
													<div>
														<span>{doc?.remaining_days} Days</span>
														<span
															className={
																doc?.alert ? "visible" : "not-visible"
															}>
															<Tooltip
																title="Deadline to file reply for this mark has passed"
																className="toolTip-custom">
																<i
																	className="fa fa-exclamation-circle"
																	aria-hidden="true"
																	style={{
																		color: "#e1001b",
																		paddingLeft: "5px",
																		cursor: "pointer",
																	}}></i>
															</Tooltip>
														</span>
													</div>
												</div>
											</p>
										))}
									{dashboardType === "third_party_opposed_trademark" &&
										record.correspondence_notices &&
										record.correspondence_notices?.length > 0 &&
										record.correspondence_notices.map((doc) => (
											<p className="h_80px vertical_scroll">
												<div>
													<span
														onClick={() => handlePdfClick(doc)}
														className="application-text">
														{doc?.subject}
													</span>
													<p>
														(
														{checkValue(doc?.date_of_correspondence)
															? doc?.date_of_correspondence
															: "N.A."}
														)
													</p>
													<div>
														<span>{doc?.remaining_days} Days</span>
														<span
															className={
																doc?.alert ? "visible" : "not-visible"
															}>
															<Tooltip
																title="Deadline to file reply for this mark has passed"
																className="toolTip-custom">
																<i
																	className="fa fa-exclamation-circle"
																	aria-hidden="true"
																	style={{
																		color: "#e1001b",
																		paddingLeft: "5px",
																		cursor: "pointer",
																	}}></i>
															</Tooltip>
														</span>
													</div>
												</div>
											</p>
										))}
									{record.examination_report_documents &&
										record.examination_report_documents?.length > 0 &&
										record.examination_report_documents.map((doc) => (
											<p className="h_80px vertical_scroll">
												<span
													onClick={
														checkValue(doc?.url)
															? () => this.handlePdfClick1(doc)
															: ""
													}
													style={
														checkValue(doc?.url)
															? { cursor: "pointer", color: "#4285f4" }
															: {}
													}>
													EXAMINATION REPORT
												</span>
												<p>({checkValue(doc?.date) ? doc?.date : "N.A."})</p>
												<div>
													<span>{doc?.remaining_days} Days</span>
													<span
														className={doc?.alert ? "visible" : "not-visible"}>
														<Tooltip
															title="Deadline to file reply for this mark has passed"
															className="toolTip-custom">
															<i
																className="fa fa-exclamation-circle"
																aria-hidden="true"
																style={{
																	color: "#e1001b",
																	paddingLeft: "5px",
																	cursor: "pointer",
																}}></i>
														</Tooltip>
													</span>
												</div>
											</p>
										))}
									{record.documents?.length === 0 &&
										record.correspondence_notices?.length === 0 &&
										record.examination_report_documents?.length === 0 && (
											<p className="h_80px vertical_scroll">N.A.</p>
										)}
								</div>
							);
						},
						...getColumnFilter("dou_due_date"),
					},
					{
						title: "DISPATCH DOCUMENT | TIME REMAINING",
						className: "tm-protect-column-1",
						render: (text, record) => {
							return (
								<div>
									{record.correspondence_notices &&
										record.correspondence_notices?.length > 0 &&
										record.correspondence_notices.map((doc) => (
											<p className="h_80px vertical_scroll">
												<div>
													<span
														onClick={() => handlePdfClick(doc)}
														className="application-text">
														{doc?.subject}
													</span>
													<p>
														(
														{checkValue(doc?.date_of_dispatch)
															? doc?.date_of_dispatch
															: "N.A."}
														)
													</p>
												</div>
												<div>
													<span>{doc?.remaining_days_wrt_dod} Days</span>
													<span
														className={
															doc?.alert_wrt_dod ? "visible" : "not-visible"
														}>
														<Tooltip
															title="Deadline to file reply for this mark has passed"
															className="toolTip-custom">
															<i
																className="fa fa-exclamation-circle"
																aria-hidden="true"
																style={{
																	color: "#e1001b",
																	paddingLeft: "5px",
																	cursor: "pointer",
																}}></i>
														</Tooltip>
													</span>
												</div>
											</p>
										))}
									{record.correspondence_notices?.length === 0 && (
										<p className="h_80px vertical_scroll">N.A.</p>
									)}
								</div>
							);
						},
						...getColumnFilter("dos_due_date"),
					},
			  ]
			: []),

		...(filing_document_type === "RENEWAL"
			? [
					{
						title: "VALID UPTO",
						className: "tm-protect-column-1",
						render: (text, record) => {
							return (
								<div>
									<p className="text-camelCase">
										{checkValue(record.valid_upto) ? record.valid_upto : "N.A."}
									</p>
								</div>
							);
						},
						...getColumnFilter("valid_upto_date"),
					},
					{
						title: "RENEWAL DUE DATE",
						className: "tm-protect-column-1",
						render: (text, record) => {
							return (
								<div>
									<p className="text-camelCase">
										{checkValue(record.renewal_due_date)
											? record.renewal_due_date
											: "N.A."}
									</p>
								</div>
							);
						},
						...getColumnFilter("renewal_due_date"),
					},
			  ]
			: []),
		{
			title: "TAGS",
			dataIndex: "tags",
			className: "tm-protect-column-1",
			render: (tags) => {
				return (
					<div>
						{tags?.length > 0
							? tags.map((tag) => (
									<span
										key={tag.id}
										className="Tag"
										style={{
											color: `${tag.color_code}`,
											border: `1px solid ${tag.color_code}`,
										}}>
										{tag.name.toUpperCase()}
									</span>
							  ))
							: "N.A."}
					</div>
				);
			},
			...getColumnFilter("tags"),
		},
		{
			title: "ALERTS",
			dataIndex: "alert",
			className: "tm-protect-column-1",
			render: (text) => (
				<p className="text-camelCase">{checkValue(text) ? text : "N.A."}</p>
			),
			...getColumnFilter("alert"),
		},
		{
			title: "ACTION",
			className: "tm-protect-column-1",
			width: 100,
			render: (action, record) => {
				return (
					<div
						className="iconTable"
						style={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							flexDirection: "row",
							flexWrap: "wrap",
							gap: "15px",
						}}>
						<Tooltip placement="top" title={"Create To-do Task"}>
							<p className="alignC">
								<CarryOutTwoTone
									onClick={() => setAddTaskVisible(true)}
									style={{ cursor: "pointer", fontSize: actionIconSize }}
								/>
							</p>
						</Tooltip>
						<Tooltip placement="top" title={"Notes"}>
							<p className="alignC">
								<MessageTwoTone
									onClick={() => handleManagerNotes(action)}
									style={{ cursor: "pointer", fontSize: actionIconSize }}
								/>
							</p>
						</Tooltip>
						<Tooltip placement="top" title={"Send email"}>
							<p className="alignC" onClick={() => handleEmail(action)}>
								<i
									className="fa fa-paper-plane"
									style={{
										color: actionIconColor,
										fontSize: actionIconSize,
										cursor: "pointer",
									}}
									aria-hidden="true"></i>
							</p>
						</Tooltip>
						<Tooltip placement="top" title={"Reply Filed"}>
							<p className="alignC" onClick={() => handleReplyFiled(record)}>
								<CheckCircleTwoTone
									style={{ fontSize: actionIconSize, cursor: "pointer" }}
								/>
							</p>
						</Tooltip>
						{dashboardType === "third_party_opposed_trademark" &&
							!record?.is_not_my_opponent && (
								<Tooltip placement="top" title={"Mark As Not My Client"}>
									<p
										className="alignC"
										onClick={() => handleNotMyClient(record)}>
										<UserDeleteOutlined
											style={{
												fontSize: actionIconSize,
												color: actionIconColor,
												cursor: "pointer",
											}}
										/>
									</p>
								</Tooltip>
							)}
						{dashboardType === "third_party_opposed_trademark" &&
							record?.is_not_my_opponent && (
								<Tooltip placement="top" title={"Mark As My Client"}>
									<p
										className="alignC"
										onClick={() => handleNotMyClient(record)}>
										<UserAddOutlined
											style={{
												fontSize: actionIconSize,
												color: actionIconColor,
												cursor: "pointer",
											}}
										/>
									</p>
								</Tooltip>
							)}
					</div>
				);
			},
		},
	];

	const table = (
		<Row style={{ marginTop: "20px" }}>
			<Col xs={{ span: 24 }}>
				<Table
					rowKey={(record) => record.id}
					columns={columns}
					className="tm-protect-table"
					bordered
					loading={loading}
					rowSelection={rowSelection}
					dataSource={tableData}
					pagination={{
						pageSize: 100,
						showQuickJumper: true,
						showSizeChanger: false,
						defaultCurrent: 1,
						current: pageNumber,
						showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} `,
						total: count,
						onChange: handleNextpage,
					}}
					scroll={{ y: "calc(100vh - 380px)" }}
				/>
			</Col>
		</Row>
	);

	const replyFiledDataProps = {
		open: openReplyFiled,
		setOpen: setOpenReplyFiled,
		data: selectedData,
		handleSubmit: generateReplyFiledAndSetNotMyOpponent,
		loading: replyFiledloading,
		setLoading: setReplyFiledLoading,
		single: true,
	};

	const notMyClientDataProps = {
		open: openNotMyClient,
		setOpen: setOpenNotMyClient,
		data: selectedData,
		handleSubmit: generateReplyFiledAndSetNotMyOpponent,
		loading: notMyClientLoading,
		setLoading: setNotMyClientLoading,
		removeAction: !selectedData?.is_not_my_opponent,
		single: true,
	};

	return (
		<>
			<CreateTask
				visible={addTaskVisible}
				onCloseModal={() => setAddTaskVisible(false)}
				type="trademark"
			/>
			<ManagerNotes
				visible={managerNotes}
				onCloseModal={() => setManagerNotes(false)}
				actionType={[POST_TM_NOTES, PATCH_TM_NOTES]}
				email={checkValue(userDetails) ? userDetails?.email : ""}
				note_for={TM}
				application_number={selectedApplicationNumber}
				type={"ADD"}
			/>
			<SendEmailNew
				visible={mailObj?.visible}
				onCloseModal={() => setMailObj({ visible: false })}
				sendFrom={
					checkValue(userDetails) ? userDetails?.communication_email : ""
				}
				sendCC={checkValue(userTeam) ? userTeam?.manager : ""}
				mailContent={mailObj?.mailContent}
				product={mailObj?.product}
				subscription={mailObj?.subscription}
				report_tag={mailObj?.report_tag}
				objectType={mailObj?.objectType}
				mm_type={
					dashboardType === "your_trademark"
						? "Portfolio trademark"
						: "Third party Opposed trademark"
				}
				applicationNumber={mailObj?.applicationNumber}
				markId={mailObj?.markId}
				doc_link={true}
				sourceId={mailObj?.sourceId}
				source={`TRADEMARK`}
				sub_section="DASHBOARD"
				managerEmail={true}
			/>
			<NotMyClientComponent {...notMyClientDataProps} />
			<NewReplyFiled {...replyFiledDataProps} />
			{table}
		</>
	);
};

export default DeadlineContent;
