import React from "react";
import { useDispatch } from "react-redux";
import { Checkbox, Menu, Typography } from "antd";
import { debounce } from "lodash";
import CommonSelectWithExcludeFilter from "../../../commonComponents/filters/CommonSelectWithExcludeFilter";
import {
    updateDeadlineManagementFilterNoTags,
	updateDeadlineManagementFilterSearchValue,
	updateDeadlineManagementFilterSelectedValues,
} from "../../../../../redux/actions/managerActions/deadlineManagementDashboard";
import { default_filter_page_size } from "../../../../Watch/watchFilter/utils/utils";

const { Text } = Typography;

const SelectContainer = (props) => {
	const {
		filteredData,
		key,
		loading,
		handleGetSingleFilter,
		filing_document_type,
		dashboardType,
		tableView = false,
	} = props;
	const dispatch = useDispatch();

	const handleChange = (newValue, newOption) => {
		const tempObject = newOption.map((item) => ({
			label: item.label,
			value: item.value,
		}));
		const tempValues = newOption.map((item) => item.value);
		let body = {
			filing_document_type: filing_document_type,
			dashboardType: dashboardType,
			required_filter: filteredData.key,
			selectedValues: tempObject,
			onlyValues: tempValues,
		};
		dispatch(updateDeadlineManagementFilterSelectedValues(body));
	};

	const handleSearchDebounced = React.useCallback(
		debounce(async (newValue) => {
			let body = {
				required_filter: filteredData.key,
				page: 1,
				filter_contains_term: newValue,
			};
			handleGetSingleFilter?.(body);
		}, 500),
		[]
	);

	const handleSearch = (newValue) => {
		let body = {
			filing_document_type: filing_document_type,
			dashboardType: dashboardType,
			required_filter: filteredData.key,
			searchValue: newValue,
		};
		if (
			filteredData?.metadata?.count > default_filter_page_size ||
			filteredData?.metadata?.count === 0
		) {
			dispatch(updateDeadlineManagementFilterSearchValue(body));
			handleSearchDebounced(newValue);
		} else {
			dispatch(updateDeadlineManagementFilterSearchValue(body));
		}
	};

	const handleScroll = (event) => {
		const { scrollHeight, scrollTop, clientHeight } = event.target;
		if (filteredData.data.length === filteredData.metadata.count) {
			return;
		}
		if (
			scrollTop + clientHeight >= scrollHeight - 20 &&
			filteredData.data.length < filteredData.metadata.count &&
			!filteredData.loading
		) {
			// handle pagination for individual filters here  ------------------------------
			let body = {
				required_filter: filteredData.key.split("_")[1],
				page: filteredData.metadata.page + 1,
				search_term: filteredData.searchValue ? filteredData.searchValue : "",
			};
			handleGetSingleFilter?.(body);
		}
	};

	let extraTagChildren = (
		<div style={{ margin: "10px", marginLeft: "20px" }}>
			<Checkbox
				checked={filteredData?.noTags ?? false}
				onChange={(event) => {
                    let body = {
                        filing_document_type: filing_document_type,
                        dashboardType: dashboardType,
                        required_filter: filteredData.key,
                        checked: event.target.checked
                    };
                    dispatch(updateDeadlineManagementFilterNoTags(body))
				}}
				disabled={filteredData?.onlyValues?.length > 0}>
				<Text style={{ fontWeight: "400" }}>No Tags</Text>
			</Checkbox>
		</div>
	);

	const showExtraChildren = () => {
		switch (filteredData?.key) {
			case "TAG":
				return extraTagChildren;

			default:
				return null;
		}
	};

	return (
		<CommonSelectWithExcludeFilter
			{...props}
			filteredData={filteredData}
			loading={loading}
			handleChange={handleChange}
			handleSearch={handleSearch}
			handleScroll={handleScroll}
			value={filteredData.selectedValues}
			exclude={filteredData?.excludeValues ?? []}
			tableView={tableView}
			rename=""
			extraHighlight={filteredData?.noTags}
			filterId={key}
			hideExclude={true}
			extraChildren={showExtraChildren()}
            extraDisableOption={filteredData?.noTags ?? false}
		/>
	);
};

export default SelectContainer;
