import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Checkbox, Modal, Typography } from "antd";
import moment from "moment";
import { setStoreData } from "../../../../../redux/actions/commonActions/actions";
import { getCalendar } from "../../../../../redux/actions/managerActions/calendar/actions";
import { checkValue } from "../../../../../functions/commonFunctions/checkValueFunctions";
import CommonCalendar from "../../../commonComponents/calendarComponent/CommonCalendar";

const { Text } = Typography;

const CalendarComponent = (props) => {
	const [isLoading, setIsLoading] = useState(false);
	const [date, setDate] = useState(null);
	const [selectedEvent, setSelectedEvent] = useState({});
	const [selectedEventType, setSelectedEventType] = useState(null);
	const [modalsVisibility, setModalsVisibility] = useState(false);

	const dispatch = useDispatch();
	const {
		events,
		activeCalendars,
		THIRD_PARTY_checkAll,
		THIRD_PARTY_indeterminate,
		currentDate,
		start,
		end,
		TODO_TASKS,

		THIRD_PARTY_OPPOSED_TRADEMARK_PENDING_REPLIES,
		STATUS_TRACKER_MARKS_OPPOSITION_DEADLINE,
		THIRD_PARTY_OPPOSED_TRADEMARK_TLA_HEARINGS_IP_INDIA,
		THIRD_PARTY_OPPOSED_TRADEMARK_TLA_HEARINGS_CAUSE_LIST,
		THIRD_PARTY_OPPOSED_TRADEMARK_OPPOSITION_HEARINGS_CAUSE_LIST,
		THIRD_PARTY_OPPOSED_TRADEMARK_OPPOSITION_HEARINGS_IP_INDIA,
		THIRD_PARTY_OPPOSED_TRADEMARK_PENDING_REPLIES_DISPATCH_DATE,
		THIRD_PARTY_OPPOSED_TRADEMARK_TM57_SECTION19_HEARINGS_CAUSE_LIST,
		THIRD_PARTY_OPPOSED_TRADEMARK_POST_REGISTRATION_HEARINGS_CAUSE_LIST,

		TODO_TASKSStatus,

		THIRD_PARTY_OPPOSED_TRADEMARK_PENDING_REPLIESStatus,
		THIRD_PARTY_OPPOSED_TRADEMARK_TLA_HEARINGS_IP_INDIAStatus,
		THIRD_PARTY_OPPOSED_TRADEMARK_TLA_HEARINGS_CAUSE_LISTStatus,
		THIRD_PARTY_OPPOSED_TRADEMARK_OPPOSITION_HEARINGS_CAUSE_LISTStatus,
		THIRD_PARTY_OPPOSED_TRADEMARK_OPPOSITION_HEARINGS_IP_INDIAStatus,
		THIRD_PARTY_OPPOSED_TRADEMARK_PENDING_REPLIES_DISPATCH_DATEStatus,
		STATUS_TRACKER_MARKS_OPPOSITION_DEADLINEStatus,
		THIRD_PARTY_OPPOSED_TRADEMARK_TM57_SECTION19_HEARINGS_CAUSE_LISTStatus,
		THIRD_PARTY_OPPOSED_TRADEMARK_POST_REGISTRATION_HEARINGS_CAUSE_LISTStatus,
	} = useSelector((state) => state.managerState.calendar);

	const calendarEventsData = {
		TODO_TASKS,
		THIRD_PARTY_OPPOSED_TRADEMARK_PENDING_REPLIES,
		STATUS_TRACKER_MARKS_OPPOSITION_DEADLINE,
		THIRD_PARTY_OPPOSED_TRADEMARK_TLA_HEARINGS_IP_INDIA,
		THIRD_PARTY_OPPOSED_TRADEMARK_TLA_HEARINGS_CAUSE_LIST,
		THIRD_PARTY_OPPOSED_TRADEMARK_OPPOSITION_HEARINGS_CAUSE_LIST,
		THIRD_PARTY_OPPOSED_TRADEMARK_OPPOSITION_HEARINGS_IP_INDIA,
		THIRD_PARTY_OPPOSED_TRADEMARK_PENDING_REPLIES_DISPATCH_DATE,
		THIRD_PARTY_OPPOSED_TRADEMARK_TM57_SECTION19_HEARINGS_CAUSE_LIST,
		THIRD_PARTY_OPPOSED_TRADEMARK_POST_REGISTRATION_HEARINGS_CAUSE_LIST,
	};

	const mergeEvents = (cals) => {
		let mergedEvents = [];

		const keys = Object.keys(cals || {});
		keys.forEach((key, index) => {
			if (cals[key] === true) {
				if (
					calendarEventsData[key] !== null &&
					calendarEventsData[key]?.length > 0
				) {
					mergedEvents = [...mergedEvents, ...calendarEventsData[key]];
				}
			}
		});
		dispatch(setStoreData({ events: mergedEvents }));
	};

	const handleEventClick = (clickInfo) => {
		setSelectedEvent(clickInfo?.event);
		setSelectedEventType(clickInfo?.event?.extendedProps?.event_type);
		setModalsVisibility(true);
	};

	const handleActiveCalendars = (key, value) => {
		if (
			[
				"THIRD_PARTY_OPPOSED_TRADEMARK_TLA_HEARINGS_CAUSE_LIST",
				"THIRD_PARTY_OPPOSED_TRADEMARK_TLA_HEARINGS_IP_INDIA",
				"THIRD_PARTY_OPPOSED_TRADEMARK_OPPOSITION_HEARINGS_CAUSE_LIST",
				"THIRD_PARTY_OPPOSED_TRADEMARK_OPPOSITION_HEARINGS_IP_INDIA",
				"THIRD_PARTY_OPPOSED_TRADEMARK_PENDING_REPLIES",
				"STATUS_TRACKER_MARKS_OPPOSITION_DEADLINE",
				"THIRD_PARTY_OPPOSED_TRADEMARK_PENDING_REPLIES_DISPATCH_DATE",
				"THIRD_PARTY_OPPOSED_TRADEMARK_TM57_SECTION19_HEARINGS_CAUSE_LIST",
				"THIRD_PARTY_OPPOSED_TRADEMARK_POST_REGISTRATION_HEARINGS_CAUSE_LIST",
			].includes(key)
		) {
			let cals = {
				...activeCalendars,
				[key]: value,
			};
			dispatch(
				setStoreData({
					activeCalendars: cals,
					THIRD_PARTY_indeterminate:
						cals["THIRD_PARTY_OPPOSED_TRADEMARK_TLA_HEARINGS_CAUSE_LIST"] ===
							false &&
						cals["THIRD_PARTY_OPPOSED_TRADEMARK_TLA_HEARINGS_IP_INDIA"] ===
							false &&
						cals[
							"THIRD_PARTY_OPPOSED_TRADEMARK_OPPOSITION_HEARINGS_CAUSE_LIST"
						] === false &&
						cals[
							"THIRD_PARTY_OPPOSED_TRADEMARK_OPPOSITION_HEARINGS_IP_INDIA"
						] === false &&
						cals["THIRD_PARTY_OPPOSED_TRADEMARK_PENDING_REPLIES"] === false &&
						cals["STATUS_TRACKER_MARKS_OPPOSITION_DEADLINE"] === false &&
						cals[
							"THIRD_PARTY_OPPOSED_TRADEMARK_PENDING_REPLIES_DISPATCH_DATE"
						] === false &&
						cals[
							"THIRD_PARTY_OPPOSED_TRADEMARK_TM57_SECTION19_HEARINGS_CAUSE_LIST"
						] === false &&
						cals[
							"THIRD_PARTY_OPPOSED_TRADEMARK_POST_REGISTRATION_HEARINGS_CAUSE_LIST"
						] === false
							? false
							: true,

					THIRD_PARTY_checkAll:
						cals["THIRD_PARTY_OPPOSED_TRADEMARK_TLA_HEARINGS_CAUSE_LIST"] ===
							false &&
						cals["THIRD_PARTY_OPPOSED_TRADEMARK_TLA_HEARINGS_IP_INDIA"] ===
							false &&
						cals[
							"THIRD_PARTY_OPPOSED_TRADEMARK_OPPOSITION_HEARINGS_CAUSE_LIST"
						] === false &&
						cals[
							"THIRD_PARTY_OPPOSED_TRADEMARK_OPPOSITION_HEARINGS_IP_INDIA"
						] === false &&
						cals["THIRD_PARTY_OPPOSED_TRADEMARK_PENDING_REPLIES"] === false &&
						cals["STATUS_TRACKER_MARKS_OPPOSITION_DEADLINE"] === false &&
						cals[
							"THIRD_PARTY_OPPOSED_TRADEMARK_PENDING_REPLIES_DISPATCH_DATE"
						] === false &&
						cals[
							"THIRD_PARTY_OPPOSED_TRADEMARK_TM57_SECTION19_HEARINGS_CAUSE_LIST"
						] === false &&
						cals[
							"THIRD_PARTY_OPPOSED_TRADEMARK_POST_REGISTRATION_HEARINGS_CAUSE_LIST"
						] === false
							? false
							: true,
				})
			);
		} else {
			dispatch(
				setStoreData({
					activeCalendars: {
						...activeCalendars,
						[key]: value,
					},
				})
			);
		}
		if (!value) {
			dispatch(
				setStoreData({
					activeCalendars: {
						...activeCalendars,
						[key]: value,
					},
				})
			);
		} else {
			if (calendarEventsData[key] === null) {
				dispatch(
					getCalendar({
						date: currentDate || date,
						required_field: key,
					})
				);
			}
		}
		mergeEvents({
			...activeCalendars,
			[key]: value,
		});
	};

	const onCheckAllChange = (e, key) => {
		let currentCals = activeCalendars;
		let selectedCals = {};
		selectedCals = e.target.checked
			? {
					...currentCals,
					THIRD_PARTY_OPPOSED_TRADEMARK_PENDING_REPLIES: true,
					STATUS_TRACKER_MARKS_OPPOSITION_DEADLINE: true,
					THIRD_PARTY_OPPOSED_TRADEMARK_TLA_HEARINGS_CAUSE_LIST: true,
					THIRD_PARTY_OPPOSED_TRADEMARK_TLA_HEARINGS_IP_INDIA: true,
					THIRD_PARTY_OPPOSED_TRADEMARK_OPPOSITION_HEARINGS_CAUSE_LIST: true,
					THIRD_PARTY_OPPOSED_TRADEMARK_OPPOSITION_HEARINGS_IP_INDIA: true,
					THIRD_PARTY_OPPOSED_TRADEMARK_PENDING_REPLIES_DISPATCH_DATE: true,
					THIRD_PARTY_OPPOSED_TRADEMARK_TM57_SECTION19_HEARINGS_CAUSE_LIST: true,
					THIRD_PARTY_OPPOSED_TRADEMARK_POST_REGISTRATION_HEARINGS_CAUSE_LIST: true,
			  }
			: {
					...currentCals,
					THIRD_PARTY_OPPOSED_TRADEMARK_PENDING_REPLIES: false,
					STATUS_TRACKER_MARKS_OPPOSITION_DEADLINE: false,
					THIRD_PARTY_OPPOSED_TRADEMARK_TLA_HEARINGS_CAUSE_LIST: false,
					THIRD_PARTY_OPPOSED_TRADEMARK_TLA_HEARINGS_IP_INDIA: false,
					THIRD_PARTY_OPPOSED_TRADEMARK_OPPOSITION_HEARINGS_CAUSE_LIST: false,
					THIRD_PARTY_OPPOSED_TRADEMARK_OPPOSITION_HEARINGS_IP_INDIA: false,
					THIRD_PARTY_OPPOSED_TRADEMARK_PENDING_REPLIES_DISPATCH_DATE: false,
					THIRD_PARTY_OPPOSED_TRADEMARK_TM57_SECTION19_HEARINGS_CAUSE_LIST: false,
					THIRD_PARTY_OPPOSED_TRADEMARK_POST_REGISTRATION_HEARINGS_CAUSE_LIST: false,
			  };

		dispatch(
			setStoreData({
				THIRD_PARTY_indeterminate: false,
				THIRD_PARTY_checkAll: e.target.checked,
				activeCalendars: selectedCals,
				events: e.target.checked ? events : [],
			})
		);

		if (e.target.checked) {
			const keys = Object.keys(selectedCals || {});
			keys.forEach((key, index) => {
				if (selectedCals[key] === true) {
					if (calendarEventsData[key] === null) {
						dispatch(
							getCalendar({
								date: currentDate || date,
								required_field: key,
							})
						);
					}
				}
			});
		}
		mergeEvents(selectedCals);
	};

	const handleTrademarkProfile = (application_number, management_mark_id) => {
		if (application_number && management_mark_id) {
			sessionStorage.setItem("management_trademark_id", management_mark_id);
			sessionStorage.setItem(
				"trademark_application_number",
				application_number
			);
			window.open(
				"/manager/trademark/opposed-trademark/trademark-profile/" + management_mark_id,
				"_blank"
			);
		}
	};

	const handlePatentProfile = (data) => {
		if (data) {
			sessionStorage.setItem("management_patent_id", data.management_mark_id);
			sessionStorage.setItem(
				"patent_application_number",
				data.application_number
			);
			window.open(
				"/manager/patent/manage-patent-profile/" + data.management_mark_id
			);
		}
	};

	const renderCalendarSideBar = () => {
		const eventsMenu = (
			<div className="calendar-checkbox">
				<Checkbox
					disabled={isLoading}
					checked={activeCalendars?.TODO_TASKS}
					className="todo-checkbox"
					onChange={(event) =>
						handleActiveCalendars("TODO_TASKS", event.target.checked)
					}>
					<Text className="text-camelCase" style={{ fontWeight: "700" }}>
						Todo
					</Text>
				</Checkbox>
				<Checkbox
					indeterminate={THIRD_PARTY_indeterminate}
					onChange={(e) => onCheckAllChange(e, "THIRD_PARTY")}
					checked={THIRD_PARTY_checkAll}
					className="all-checkbox"
					disabled={isLoading}>
					<Text className="text-camelCase" style={{ fontWeight: "700" }}>
						3rd Party
					</Text>
				</Checkbox>
				<hr
					style={{
						border: "1px solid lightgray",
						width: "100%",
						margin: 0,
					}}
				/>
				<Checkbox
					disabled={isLoading}
					checked={
						activeCalendars?.THIRD_PARTY_OPPOSED_TRADEMARK_PENDING_REPLIES
					}
					className="tp-reply-checkbox"
					onChange={(event) =>
						handleActiveCalendars(
							"THIRD_PARTY_OPPOSED_TRADEMARK_PENDING_REPLIES",
							event.target.checked
						)
					}>
					<Text className="text-camelCase" style={{ fontWeight: "400" }}>
						Pending Reply (Date of Upload)
					</Text>
				</Checkbox>
				<Checkbox
					disabled={isLoading}
					checked={
						activeCalendars?.THIRD_PARTY_OPPOSED_TRADEMARK_TLA_HEARINGS_CAUSE_LIST
					}
					className="tp-tla-cause-checkbox"
					onChange={(event) =>
						handleActiveCalendars(
							"THIRD_PARTY_OPPOSED_TRADEMARK_TLA_HEARINGS_CAUSE_LIST",
							event.target.checked
						)
					}>
					<Text className="text-camelCase" style={{ fontWeight: "400" }}>
						TLA Hearing (Cause List)
					</Text>
				</Checkbox>
				<Checkbox
					disabled={isLoading}
					checked={
						activeCalendars?.THIRD_PARTY_OPPOSED_TRADEMARK_TLA_HEARINGS_IP_INDIA
					}
					onChange={(event) =>
						handleActiveCalendars(
							"THIRD_PARTY_OPPOSED_TRADEMARK_TLA_HEARINGS_IP_INDIA",
							event.target.checked
						)
					}
					className="tp-tla-ip-checkbox">
					<Text className="text-camelCase" style={{ fontWeight: "400" }}>
						TLA Hearing (IP-India)
					</Text>
				</Checkbox>
				<Checkbox
					disabled={isLoading}
					checked={
						activeCalendars?.THIRD_PARTY_OPPOSED_TRADEMARK_OPPOSITION_HEARINGS_CAUSE_LIST
					}
					onChange={(event) =>
						handleActiveCalendars(
							"THIRD_PARTY_OPPOSED_TRADEMARK_OPPOSITION_HEARINGS_CAUSE_LIST",
							event.target.checked
						)
					}
					className="tp-opp-cause-checkbox">
					<Text className="text-camelCase" style={{ fontWeight: "400" }}>
						Opposition Hearing (Cause List)
					</Text>
				</Checkbox>
				<Checkbox
					disabled={isLoading}
					checked={
						activeCalendars?.THIRD_PARTY_OPPOSED_TRADEMARK_OPPOSITION_HEARINGS_IP_INDIA
					}
					onChange={(event) =>
						handleActiveCalendars(
							"THIRD_PARTY_OPPOSED_TRADEMARK_OPPOSITION_HEARINGS_IP_INDIA",
							event.target.checked
						)
					}
					className="tp-opp-ip-checkbox">
					<Text className="text-camelCase" style={{ fontWeight: "400" }}>
						Opposition Hearing (IP-India)
					</Text>
				</Checkbox>
				<Checkbox
					disabled={isLoading}
					checked={activeCalendars?.STATUS_TRACKER_MARKS_OPPOSITION_DEADLINE}
					className="tp-deadline-checkbox"
					onChange={(event) =>
						handleActiveCalendars(
							"STATUS_TRACKER_MARKS_OPPOSITION_DEADLINE",
							event.target.checked
						)
					}>
					<Text className="text-camelCase" style={{ fontWeight: "400" }}>
						Status Tracker Opposition Alert
					</Text>
				</Checkbox>

				<Checkbox
					disabled={isLoading}
					checked={
						activeCalendars?.THIRD_PARTY_OPPOSED_TRADEMARK_PENDING_REPLIES_DISPATCH_DATE
					}
					className="tpdd-checkbox"
					onChange={(event) =>
						handleActiveCalendars(
							"THIRD_PARTY_OPPOSED_TRADEMARK_PENDING_REPLIES_DISPATCH_DATE",
							event.target.checked
						)
					}>
					<Text className="text-camelCase" style={{ fontWeight: "400" }}>
						Pending Reply (Date of Service)
					</Text>
				</Checkbox>
				<Checkbox
					disabled={isLoading}
					checked={
						activeCalendars?.THIRD_PARTY_OPPOSED_TRADEMARK_TM57_SECTION19_HEARINGS_CAUSE_LIST
					}
					onChange={(event) =>
						handleActiveCalendars(
							"THIRD_PARTY_OPPOSED_TRADEMARK_TM57_SECTION19_HEARINGS_CAUSE_LIST",
							event.target.checked
						)
					}
					className="third-party-57-19-dynamic-page">
					<Text className="text-camelCase" style={{ fontWeight: "400" }}>
						TM 57(4) & Section 19 (Dynamic Page)
					</Text>
				</Checkbox>

				<Checkbox
					disabled={isLoading}
					checked={
						activeCalendars?.THIRD_PARTY_OPPOSED_TRADEMARK_POST_REGISTRATION_HEARINGS_CAUSE_LIST
					}
					onChange={(event) =>
						handleActiveCalendars(
							"THIRD_PARTY_OPPOSED_TRADEMARK_POST_REGISTRATION_HEARINGS_CAUSE_LIST",
							event.target.checked
						)
					}
					className="third-party-post-registration-cause-list">
					<Text className="text-camelCase" style={{ fontWeight: "400" }}>
						Post Registration Hearing ( Cause List )
					</Text>
				</Checkbox>
			</div>
		);

		if (
			TODO_TASKSStatus === "LOADING" ||
			THIRD_PARTY_OPPOSED_TRADEMARK_PENDING_REPLIESStatus === "LOADING" ||
			THIRD_PARTY_OPPOSED_TRADEMARK_TLA_HEARINGS_IP_INDIAStatus === "LOADING" ||
			THIRD_PARTY_OPPOSED_TRADEMARK_TLA_HEARINGS_CAUSE_LISTStatus ===
				"LOADING" ||
			THIRD_PARTY_OPPOSED_TRADEMARK_OPPOSITION_HEARINGS_CAUSE_LISTStatus ===
				"LOADING" ||
			THIRD_PARTY_OPPOSED_TRADEMARK_OPPOSITION_HEARINGS_IP_INDIAStatus ===
				"LOADING" ||
			THIRD_PARTY_OPPOSED_TRADEMARK_PENDING_REPLIES_DISPATCH_DATEStatus ===
				"LOADING" ||
			STATUS_TRACKER_MARKS_OPPOSITION_DEADLINEStatus === "LOADING" ||
			THIRD_PARTY_OPPOSED_TRADEMARK_TM57_SECTION19_HEARINGS_CAUSE_LISTStatus ===
				"LOADING" ||
			THIRD_PARTY_OPPOSED_TRADEMARK_POST_REGISTRATION_HEARINGS_CAUSE_LISTStatus ===
				"LOADING"
		) {
			setIsLoading(true);
		} else {
			setIsLoading(false);
		}

		return (
			<div className="calendar-app-sidebar-section">
				<div
					style={{
						border: "1.5px solid rgb(234 230 230)",
						padding: "4px",
						background: "white",
						borderRadius: "10px",
					}}>
					{eventsMenu}
				</div>
			</div>
		);
	};

	const modalArea = () => (
		<Modal
			closable={false}
			footer={false}
			open={modalsVisibility}
			onOk={() => setModalsVisibility(false)}
			onCancel={() => setModalsVisibility(false)}>
			{selectedEvent && (
				<>
					<div
						style={{
							display: "flex",
							flexDirection: "column",
						}}>
						<h3>{selectedEvent?.title}</h3>
						{selectedEventType &&
							selectedEventType === "TRADEMARK_RENEWALS" && (
								<>
									<div>
										Deadline Date:{" "}
										{moment(selectedEvent?.start).format("DD MMM, YYYY")}
									</div>
									<hr
										style={{
											border: "1px solid lightgray",
											width: "100%",
										}}
									/>
									<div
										style={{
											display: "flex",
											flexDirection: "column",
											fontSize: "16px",
											gap: "0.6rem",
											color: "black",
										}}>
										<div>{`TM Applied For: ${selectedEvent?.title}`}</div>
										<div>
											Application Number :
											<span
												className="application-text"
												style={{ marginLeft: "2px" }}
												onClick={() =>
													handleTrademarkProfile(
														selectedEvent?.extendedProps?.application_number,
														selectedEvent?.extendedProps?.management_mark_id
													)
												}>
												<span>
													{checkValue(
														selectedEvent?.extendedProps?.application_number
													)
														? selectedEvent?.extendedProps?.application_number
														: "N.A."}{" "}
												</span>
											</span>
										</div>
										<div>{`Valid Upto : ${moment(selectedEvent?.start).format(
											"DD MMM, YYYY"
										)}`}</div>
										<div>
											<div>Image:</div>
											<img
												src={selectedEvent?.extendedProps?.associated_image}
												alt=""
												style={{ width: "120px", height: "120px" }}
											/>
										</div>
									</div>
								</>
							)}

						{selectedEventType &&
							(selectedEventType === "TRADEMARK_PENDING_REPLIES" ||
								selectedEventType ===
									"TRADEMARK_PENDING_REPLIES_DISPATCH_DATE") && (
								<>
									<div>
										Deadline Date:{" "}
										{moment(selectedEvent?.start).format("DD MMM, YYYY")}
									</div>
									<hr
										style={{
											border: "1px solid lightgray",
											width: "100%",
										}}
									/>
									<div
										style={{
											display: "flex",
											flexDirection: "column",
											fontSize: "16px",
											gap: "0.6rem",
											color: "black",
										}}>
										<div>{`TM Applied For: ${selectedEvent?.title}`}</div>
										<div>
											Application Number :
											<span
												className="application-text"
												style={{ marginLeft: "2px" }}
												onClick={() =>
													handleTrademarkProfile(
														selectedEvent?.extendedProps?.application_number,
														selectedEvent?.extendedProps?.management_mark_id
													)
												}>
												<span>
													{checkValue(
														selectedEvent?.extendedProps?.application_number
													)
														? selectedEvent?.extendedProps?.application_number
														: "N.A."}{" "}
												</span>
											</span>
										</div>
										{selectedEvent?.extendedProps?.documents &&
											selectedEvent?.extendedProps?.documents?.length > 0 && (
												<div>
													Documents [File-DOU]:-
													<div
														style={{
															display: "flex",
														}}>
														<ul>
															{selectedEvent?.extendedProps?.documents &&
															selectedEvent?.extendedProps?.documents?.length >
																0 ? (
																selectedEvent?.extendedProps?.documents.map(
																	(doc) => {
																		return (
																			<li>
																				<a
																					target="blank"
																					href={doc.file && doc.file}>
																					{doc.name && doc.name}
																				</a>{" "}
																				-<span>{doc?.date_of_upload}</span>
																			</li>
																		);
																	}
																)
															) : (
																<li>N.A.</li>
															)}
														</ul>
													</div>
												</div>
											)}
										{selectedEvent?.extendedProps?.notices &&
											selectedEvent?.extendedProps?.notices?.length > 0 && (
												<div>
													Notice Documents [File-DOU-DOD]:-
													<div
														style={{
															display: "flex",
														}}>
														<ul>
															{selectedEvent?.extendedProps?.notices &&
															selectedEvent?.extendedProps?.notices?.length >
																0 ? (
																selectedEvent?.extendedProps?.notices.map(
																	(notice) => {
																		return (
																			<li>
																				<a
																					target="blank"
																					href={notice.file && notice.file}>
																					{notice.name && notice.name}
																				</a>
																				<span>
																					{notice?.date_of_upload &&
																						`-${notice?.date_of_upload}`}
																				</span>
																				<span>
																					{notice?.date_of_correspondence &&
																						`-${notice?.date_of_correspondence}`}
																				</span>
																			</li>
																		);
																	}
																)
															) : (
																<li>N.A.</li>
															)}
														</ul>
													</div>
												</div>
											)}
										{selectedEvent?.extendedProps?.examination_reports &&
											selectedEvent?.extendedProps?.examination_reports
												?.length > 0 && (
												<div>
													Examination Report [File-DOE]:-
													<div
														style={{
															display: "flex",
														}}>
														<ul>
															{selectedEvent?.extendedProps
																?.examination_reports &&
															selectedEvent?.extendedProps?.examination_reports
																?.length > 0 ? (
																selectedEvent?.extendedProps?.examination_reports.map(
																	(reportObj) => {
																		return (
																			<li>
																				<a
																					target="blank"
																					href={
																						reportObj.file && reportObj.file
																					}>
																					{reportObj.name && reportObj.name}
																				</a>
																				<span>
																					{reportObj?.date_of_examination_report &&
																						`-${reportObj?.date_of_examination_report}`}
																				</span>
																			</li>
																		);
																	}
																)
															) : (
																<li>N.A.</li>
															)}
														</ul>
													</div>
												</div>
											)}

										{selectedEvent?.extendedProps?.associated_image && (
											<div>
												<div>Image:</div>

												<img
													src={selectedEvent?.extendedProps?.associated_image}
													alt=""
													style={{ width: "120px", height: "120px" }}
												/>
											</div>
										)}
									</div>
								</>
							)}

						{selectedEventType &&
							selectedEventType === "TRADEMARK_TLA_HEARINGS_CAUSE_LIST" && (
								<>
									<div>
										Deadline Date:{" "}
										{moment(selectedEvent?.start).format("DD MMM, YYYY")}
									</div>
									<hr
										style={{
											border: "1px solid lightgray",
											width: "100%",
										}}
									/>
									<div
										style={{
											display: "flex",
											flexDirection: "column",
											fontSize: "16px",
											gap: "0.6rem",
											color: "black",
										}}>
										<div>{`TM Applied For: ${selectedEvent?.extendedProps?.applied_for}`}</div>
										<div>
											Application Number :
											<span
												className="application-text"
												style={{ marginLeft: "2px" }}
												onClick={() =>
													handleTrademarkProfile(
														selectedEvent?.extendedProps?.application_number,
														selectedEvent?.extendedProps?.management_mark_id
													)
												}>
												<span>
													{checkValue(
														selectedEvent?.extendedProps?.application_number
													)
														? selectedEvent?.extendedProps?.application_number
														: "N.A."}{" "}
												</span>
											</span>
										</div>
										<div>{`TLA Hearing Date : ${moment(
											selectedEvent?.start
										).format("DD MMM, YYYY")}`}</div>
										<div>{`Office : ${
											selectedEvent?.extendedProps?.application_office?.name ||
											"N.A."
										}`}</div>
										<div>{`Officer : ${
											selectedEvent?.extendedProps?.hearing_officer || "N.A."
										}`}</div>

										{selectedEvent?.extendedProps?.documents &&
											selectedEvent?.extendedProps?.documents?.length > 0 && (
												<div>
													Documents [File-DOU]:-
													<div
														style={{
															display: "flex",
														}}>
														<ul>
															{selectedEvent?.extendedProps?.documents &&
															selectedEvent?.extendedProps?.documents?.length >
																0 ? (
																selectedEvent?.extendedProps?.documents.map(
																	(doc) => {
																		return (
																			<li>
																				<a
																					target="blank"
																					href={doc.file && doc.file}>
																					{doc.name && doc.name}
																				</a>{" "}
																				-<span>{doc?.date_of_upload}</span>
																			</li>
																		);
																	}
																)
															) : (
																<li>N.A.</li>
															)}
														</ul>
													</div>
												</div>
											)}
										{selectedEvent?.extendedProps?.notices &&
											selectedEvent?.extendedProps?.notices?.length > 0 && (
												<div>
													Notice Documents [File-DOU-DOD]:-
													<div
														style={{
															display: "flex",
														}}>
														<ul>
															{selectedEvent?.extendedProps?.notices &&
															selectedEvent?.extendedProps?.notices?.length >
																0 ? (
																selectedEvent?.extendedProps?.notices.map(
																	(notice) => {
																		return (
																			<li>
																				<a
																					target="blank"
																					href={notice.file && notice.file}>
																					{notice.name && notice.name}
																				</a>
																				<span>
																					{notice?.date_of_upload &&
																						`-${notice?.date_of_upload}`}
																				</span>
																				<span>
																					{notice?.date_of_correspondence &&
																						`-${notice?.date_of_correspondence}`}
																				</span>
																			</li>
																		);
																	}
																)
															) : (
																<li>N.A.</li>
															)}
														</ul>
													</div>
												</div>
											)}
										{selectedEvent?.extendedProps?.examination_reports &&
											selectedEvent?.extendedProps?.examination_reports
												?.length > 0 && (
												<div>
													Examination Reports [File-DOE]:-
													<div
														style={{
															display: "flex",
														}}>
														<ul>
															{selectedEvent?.extendedProps
																?.examination_reports &&
															selectedEvent?.extendedProps?.examination_reports
																?.length > 0 ? (
																selectedEvent?.extendedProps?.examination_reports.map(
																	(reportObj) => {
																		return (
																			<li>
																				<a
																					target="blank"
																					href={
																						reportObj.file && reportObj.file
																					}>
																					{reportObj.name && reportObj.name}
																				</a>
																				<span>
																					{reportObj?.date_of_examination_report &&
																						`-${reportObj?.date_of_examination_report}`}
																				</span>
																			</li>
																		);
																	}
																)
															) : (
																<li>N.A.</li>
															)}
														</ul>
													</div>
												</div>
											)}

										{selectedEvent?.extendedProps?.associated_image && (
											<div>
												<div>Image:</div>

												<img
													src={selectedEvent?.extendedProps?.associated_image}
													alt=""
													style={{ width: "120px", height: "120px" }}
												/>
											</div>
										)}
									</div>
								</>
							)}

						{selectedEventType &&
							(selectedEventType ===
								"TRADEMARK_TM57_SECTION19_HEARINGS_CAUSE_LIST" ||
								selectedEventType ===
									"TRADEMARK_POST_REGISTRATION_HEARINGS_CAUSE_LIST") && (
								<>
									<div>
										Deadline Date:{" "}
										{moment(selectedEvent?.start).format("DD MMM, YYYY")}
									</div>
									<hr
										style={{
											border: "1px solid lightgray",
											width: "100%",
										}}
									/>
									<div
										style={{
											display: "flex",
											flexDirection: "column",
											fontSize: "16px",
											gap: "0.6rem",
											color: "black",
										}}>
										<div>{`TM Applied For: ${selectedEvent?.extendedProps?.applied_for}`}</div>
										<div>
											Application Number :
											<span
												className="application-text"
												style={{ marginLeft: "2px" }}
												onClick={() =>
													handleTrademarkProfile(
														selectedEvent?.extendedProps?.application_number,
														selectedEvent?.extendedProps?.management_mark_id
													)
												}>
												<span>
													{checkValue(
														selectedEvent?.extendedProps?.application_number
													)
														? selectedEvent?.extendedProps?.application_number
														: "N.A."}{" "}
												</span>
											</span>
										</div>
										<div>{`Hearing Date : ${moment(selectedEvent?.start).format(
											"DD MMM, YYYY"
										)}`}</div>
										<div>{`Office : ${
											selectedEvent?.extendedProps?.application_office?.name ||
											"N.A."
										}`}</div>
										<div>{`Officer : ${
											selectedEvent?.extendedProps?.hearing_officer || "N.A."
										}`}</div>

										{selectedEvent?.extendedProps?.documents &&
											selectedEvent?.extendedProps?.documents?.length > 0 && (
												<div>
													Documents [File-DOU]:-
													<div
														style={{
															display: "flex",
														}}>
														<ul>
															{selectedEvent?.extendedProps?.documents &&
															selectedEvent?.extendedProps?.documents?.length >
																0 ? (
																selectedEvent?.extendedProps?.documents.map(
																	(doc) => {
																		return (
																			<li>
																				<a
																					target="blank"
																					href={doc.file && doc.file}>
																					{doc.name && doc.name}
																				</a>{" "}
																				-<span>{doc?.date_of_upload}</span>
																			</li>
																		);
																	}
																)
															) : (
																<li>N.A.</li>
															)}
														</ul>
													</div>
												</div>
											)}
										{selectedEvent?.extendedProps?.notices &&
											selectedEvent?.extendedProps?.notices?.length > 0 && (
												<div>
													Notice Documents [File-DOU-DOD]:-
													<div
														style={{
															display: "flex",
														}}>
														<ul>
															{selectedEvent?.extendedProps?.notices &&
															selectedEvent?.extendedProps?.notices?.length >
																0 ? (
																selectedEvent?.extendedProps?.notices.map(
																	(notice) => {
																		return (
																			<li>
																				<a
																					target="blank"
																					href={notice.file && notice.file}>
																					{notice.name && notice.name}
																				</a>
																				<span>
																					{notice?.date_of_upload &&
																						`-${notice?.date_of_upload}`}
																				</span>
																				<span>
																					{notice?.date_of_correspondence &&
																						`-${notice?.date_of_correspondence}`}
																				</span>
																			</li>
																		);
																	}
																)
															) : (
																<li>N.A.</li>
															)}
														</ul>
													</div>
												</div>
											)}
										{selectedEvent?.extendedProps?.examination_reports &&
											selectedEvent?.extendedProps?.examination_reports
												?.length > 0 && (
												<div>
													Examination Reports [File-DOE]:-
													<div
														style={{
															display: "flex",
														}}>
														<ul>
															{selectedEvent?.extendedProps
																?.examination_reports &&
															selectedEvent?.extendedProps?.examination_reports
																?.length > 0 ? (
																selectedEvent?.extendedProps?.examination_reports.map(
																	(reportObj) => {
																		return (
																			<li>
																				<a
																					target="blank"
																					href={
																						reportObj.file && reportObj.file
																					}>
																					{reportObj.name && reportObj.name}
																				</a>
																				<span>
																					{reportObj?.date_of_examination_report &&
																						`-${reportObj?.date_of_examination_report}`}
																				</span>
																			</li>
																		);
																	}
																)
															) : (
																<li>N.A.</li>
															)}
														</ul>
													</div>
												</div>
											)}

										{selectedEvent?.extendedProps?.associated_image && (
											<div>
												<div>Image:</div>

												<img
													src={selectedEvent?.extendedProps?.associated_image}
													alt=""
													style={{ width: "120px", height: "120px" }}
												/>
											</div>
										)}
									</div>
								</>
							)}

						{selectedEventType &&
							(selectedEventType ===
								"TRADEMARK_OPPOSITION_HEARINGS_CAUSE_LIST" ||
								selectedEventType ===
									"TRADEMARK_OPPOSITION_HEARINGS_IP_INDIA") && (
								<>
									<div>
										Deadline Date:{" "}
										{moment(selectedEvent?.start).format("DD MMM, YYYY")}
									</div>
									<hr
										style={{
											border: "1px solid lightgray",
											width: "100%",
										}}
									/>
									<div
										style={{
											display: "flex",
											flexDirection: "column",
											fontSize: "16px",
											gap: "0.6rem",
											color: "black",
										}}>
										<div>{`TM Applied For: ${selectedEvent?.extendedProps?.applied_for}`}</div>
										<div>
											Application Number :
											<span
												className="application-text"
												style={{ marginLeft: "2px" }}
												onClick={() =>
													handleTrademarkProfile(
														selectedEvent?.extendedProps?.application_number,
														selectedEvent?.extendedProps?.management_mark_id
													)
												}>
												<span>
													{checkValue(
														selectedEvent?.extendedProps?.application_number
													)
														? selectedEvent?.extendedProps?.application_number
														: "N.A."}{" "}
												</span>
											</span>
										</div>
										<div>{`TLA Hearing Date : ${moment(
											selectedEvent?.start
										).format("DD MMM, YYYY")}`}</div>
										<div>{`Office : ${
											selectedEvent?.extendedProps?.application_office?.name ||
											"N.A."
										}`}</div>
										<div>{`Officer : ${
											selectedEvent?.extendedProps?.hearing_officer || "N.A."
										}`}</div>
										{selectedEvent?.extendedProps?.associated_image && (
											<div>
												<div>Image:</div>

												<img
													src={selectedEvent?.extendedProps?.associated_image}
													alt=""
													style={{ width: "120px", height: "120px" }}
												/>
											</div>
										)}
									</div>
								</>
							)}

						{selectedEventType &&
							selectedEventType === "TRADEMARK_TLA_HEARINGS_IP_INDIA" && (
								<>
									<div>
										Deadline Date:{" "}
										{moment(selectedEvent?.start).format("DD MMM, YYYY")}
									</div>
									<hr
										style={{
											border: "1px solid lightgray",
											width: "100%",
										}}
									/>
									<div
										style={{
											display: "flex",
											flexDirection: "column",
											fontSize: "16px",
											gap: "0.6rem",
											color: "black",
										}}>
										<div>{`TM Applied For: ${selectedEvent?.extendedProps?.applied_for}`}</div>
										<div>
											Application Number :
											<span
												className="application-text"
												style={{ marginLeft: "2px" }}
												onClick={() =>
													handleTrademarkProfile(
														selectedEvent?.extendedProps?.application_number,
														selectedEvent?.extendedProps?.management_mark_id
													)
												}>
												<span>
													{checkValue(
														selectedEvent?.extendedProps?.application_number
													)
														? selectedEvent?.extendedProps?.application_number
														: "N.A."}{" "}
												</span>
											</span>
										</div>
										<div>{`TLA Hearing Date : ${moment(
											selectedEvent?.start
										).format("DD MMM, YYYY")}`}</div>
										<div>{`Office : ${
											selectedEvent?.extendedProps?.application_office?.name ||
											"N.A."
										}`}</div>
										<div>{`Officer : ${
											selectedEvent?.extendedProps?.hearing_officer || "N.A."
										}`}</div>
										{selectedEvent?.extendedProps?.examination_reports &&
											selectedEvent?.extendedProps?.examination_reports
												?.length > 0 && (
												<div>
													Examination Report [File-DOE]:-
													<div
														style={{
															display: "flex",
														}}>
														<ul>
															{selectedEvent?.extendedProps
																?.examination_reports &&
															selectedEvent?.extendedProps?.examination_reports
																?.length > 0 ? (
																selectedEvent?.extendedProps?.examination_reports.map(
																	(reportObj) => {
																		return (
																			<li>
																				<a
																					target="blank"
																					href={
																						reportObj.file && reportObj.file
																					}>
																					{reportObj.name && reportObj.name}
																				</a>
																				<span>
																					{reportObj?.date_of_examination_report &&
																						`-${reportObj?.date_of_examination_report}`}
																				</span>
																			</li>
																		);
																	}
																)
															) : (
																<li>N.A.</li>
															)}
														</ul>
													</div>
												</div>
											)}
										{selectedEvent?.extendedProps?.associated_image && (
											<div>
												<div>Image:</div>

												<img
													src={selectedEvent?.extendedProps?.associated_image}
													alt=""
													style={{ width: "120px", height: "120px" }}
												/>
											</div>
										)}
									</div>
								</>
							)}

						{selectedEventType && selectedEventType === "TODO_TASKS" && (
							<>
								<div>
									Deadline Date:{" "}
									{moment(selectedEvent?.start).format("DD MMM, YYYY")}
								</div>
								<hr
									style={{
										border: "1px solid lightgray",
										width: "100%",
									}}
								/>
								<div
									style={{
										display: "flex",
										flexDirection: "column",
										fontSize: "16px",
										gap: "0.6rem",
										color: "black",
									}}>
									<div
										style={{
											maxHeight: "200px",
											overflow: "auto",
										}}>
										{selectedEvent?.extendedProps?.description}
									</div>
									<div>
										Application Number :
										{selectedEvent?.extendedProps?.application_number?.length >
										0 ? (
											<span
												className="application-text"
												style={{ marginLeft: "2px" }}
												onClick={() =>
													handlePatentProfile({
														application_number:
															selectedEvent?.extendedProps?.application_number,
														management_mark_id:
															selectedEvent?.extendedProps?.management_mark_id,
													})
												}>
												<span>
													{checkValue(
														selectedEvent?.extendedProps?.application_number
													)
														? selectedEvent?.extendedProps?.application_number
														: "N.A."}{" "}
												</span>
											</span>
										) : (
											<span style={{ margin: "0px 4px" }}>N.A.</span>
										)}
									</div>
									<div>Reminder Dates:- </div>
									<div
										style={{
											display: "flex",
										}}>
										<ul>
											{selectedEvent?.extendedProps?.reminder_date &&
											selectedEvent?.extendedProps?.reminder_date?.length >
												0 ? (
												[...selectedEvent?.extendedProps?.reminder_date]?.map(
													(data, key) => {
														return (
															<li
																key={key}
																style={{
																	margin: "0px 4px",
																}}
																className="text-camelCase">
																{data}
															</li>
														);
													}
												)
											) : (
												<li>N.A.</li>
											)}
										</ul>
									</div>
								</div>
							</>
						)}
					</div>
				</>
			)}
			<div
				style={{
					display: "flex",
					justifyContent: "flex-end",
				}}>
				<Button
					onClick={() => {
						setModalsVisibility(false);
					}}
					type="primary">
					Close
				</Button>
			</div>
		</Modal>
	);

	return (
		<div
			style={{
				border: "1.5px solid rgb(234 230 230)",
				padding: "4px",
				background: "white",
				borderRadius: "10px",
				marginTop: "40px",
			}}>
			<div
				style={{
					padding: "10px 20px 10px",
					borderBottom: "1.5px solid rgb(234 230 230)",
				}}>
				<h5>Calendar</h5>
			</div>
			{modalArea()}
			<CommonCalendar
				sidebar={renderCalendarSideBar}
				activeCalendars={activeCalendars}
				events={events}
				isLoading={isLoading}
				handleEventClick={handleEventClick}
				start={start}
				end={end}
			/>
		</div>
	);
};

export default CalendarComponent;
