//--------LIBRARIES--------
import { combineReducers } from "redux";

//--------REDUCERS--------
import trademarkDashboard from "./trademarkDashboard";
import patentDashboard from "./patentDashboard";
import profile from "./profile";
import report from "./report";
import todoTask from "./to-do-task";
// import trademark from './trademark';
// import hearing from './trademark/hearing';
import notes from "./notes";
import correspondance from "./correspondance";
import managerEmail from "./managerEmail";
import workflow from "./workflow";
import notesDashboard from "./notesDashboard";
import calendar from "./calendar";
import unPublishedPatentDashboard from "./unPublishedPatentDashboard";
import deadlineManagementDashboard from "./deadlineManagementDashboard";

export default combineReducers({
  trademarkDashboardState: trademarkDashboard,
  patentDashboardState: patentDashboard,
  profileState: profile,
  reportState: report,
  todoTaskState: todoTask,
  trademarkState: null,
  hearingState: null,
  notesState: notes,
  correspondanceState: correspondance,
  managerEmailState: managerEmail,
  workflowState: workflow,
  notesDashboard: notesDashboard,
  calendar: calendar,
  unPublishedPatent: unPublishedPatentDashboard,
  deadlineManagement: deadlineManagementDashboard
});
