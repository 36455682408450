import React, { useEffect, useState } from "react";
import {
	default_filter_page
} from "../../../Watch/watchFilter/utils/utils";
import { useDispatch, useSelector } from "react-redux";
import { Badge, Button, Checkbox, Col, Input, notification, Row, Switch, Tooltip, Typography } from "antd";
import SelectContainer from "../commonComponents/filterContainer.jsx/SelectContainer";
import {
	CheckCircleTwoTone,
	FilterFilled,
	MinusCircleOutlined,
    UserDeleteOutlined,
} from "@ant-design/icons";
import {
	emptyDeadlineManagementFilterStructure,
	updateDeadlineManagementBooleanFilterValues,
	updateDeadlineManagementContainsTerm,
	updateDeadlineManagementCreateReport,
	updateDeadlineManagementIsFilterApplied,
	updateDeadlineManagementRenewalCreateReport,
	updateDeadlineManagementRenewalReplyFiled,
	updateDeadlineManagementReplyFiledOrNotMyClient,
	updateDeadlineManagementSeeAllHearings,
} from "../../../../redux/actions/managerActions/deadlineManagementDashboard";
import NewReplyFiled from "../commonComponents/modalComponents/NewReplyFiled";
import CreateReport from "../../commonComponents/customReport/CustomReport";
import NotMyClientComponent from "../commonComponents/modalComponents/NotMyClientComponent";
import MultipleCheckboxComponents from "../commonComponents/filterContainer.jsx/MultipleCheckboxComponent";

const { Text } = Typography;

const DeadlineFilters = (props) => {
	const {
		filing_document_type,
		changeSwitch,
		deadlineMMType,
		dashboardType,
		getSingleFilterData,
		getIsFilterSelected,
		fetchDeadlineData,
		selected,
        selectedRows,
		setSelected,
		selectAll,
		setSelectAll,
		setSelectedRows,
        generateFilterObject,
		renewalChangeSwitch,
		setRenewalChangeSwitch
	} = props;

	const [openReplyFiled, setOpenReplyFiled] = useState(false);
    const [replyFiledloading, setReplyFiledLoading] = useState(false);
    const [openCreateReport, setOpenCreateReport] = useState(false);
    const [openNotMyClient, setOpenNotMyClient] = useState(false);
    const [notMyClientLoading, setNotMyClientLoading] = useState(false);

	const { config = {}, isFilterRendered } = useSelector(
		(state) =>
			state.managerState?.deadlineManagement?.deadlineFilters?.[
				dashboardType
			]?.[filing_document_type] || {}
	);

	const { userSubscriptions } = useSelector((state) => state.userSubscriptions);

	const {
		trademarkTypeConfig = {},
		trademarkStateConfig = {},
		upcoming_see_all_hearings,
		contains_term,
		isFilterApplied,
        multiple_opponent_flag,
		show_all_opponents,
		serviced,
	} = config;

	const { loading, tableData,pageNumber,report_columns } = useSelector(
		(state) =>
			state.managerState?.deadlineManagement?.dealineContent?.[dashboardType]?.[
				filing_document_type
			]?.config || {}
	);

	const dispatch = useDispatch();

	const fetchFilters = (extraBody = {}) => {
        let commonKeys = [
            "PROPRIETOR",
			"CLASSES",
			"TRADEMARK_TYPE",
			"TAG",
			"TRADEMARK_STATUS",
			"TRADEMARK_STATES",
			"ALERTS",
        ]

        let extraPendingRepliesKey = ["TRADEMARK_OPPONENT"]
		let filterKeys = filing_document_type === "RENEWAL" ? commonKeys :[...commonKeys,...extraPendingRepliesKey];

		filterKeys.map((item) => {
			let body = {
				...extraBody,
				required_filter: item,
				filter_contains_term: "",
				page: default_filter_page,
			};
			getSingleFilterData?.(body);
		});
	};

	useEffect(() => {
        if(!isFilterRendered){
            fetchFilters();
        }
	}, []);

	const handleApplyFilters = () => {
		let body = {
			page: 1,
		};
		fetchDeadlineData?.(body);
		let bodyForStoreChanges = {
			filing_document_type: filing_document_type,
			dashboardType: dashboardType,
			value: true,
		};
		dispatch(updateDeadlineManagementIsFilterApplied(bodyForStoreChanges));
	};

	const handleResetFilters = (extraBody = {}) => {
		let body = {
			...extraBody,
			page: 1,
			reset: true,
		};
		fetchDeadlineData?.(body);
		let bodyForStoreChanges = {
			filing_document_type: filing_document_type,
			dashboardType: dashboardType,
		};
		dispatch(emptyDeadlineManagementFilterStructure(bodyForStoreChanges));
	};

    const generateReplyFiledAndSetNotMyOpponent = (body) => {
        const updatedBody = {
            pseudo_flag: changeSwitch,
            mm_type: deadlineMMType,
            filing_document_type: filing_document_type,
            is_reply_filed: body?.is_reply_filed ?? false,
            is_not_my_opponent: body?.is_not_my_opponent ?? false,
            selected_ids: selected ?? [],
			select_all : selectAll,
            filter_flag: false,
            filters: {},
            upcoming: !upcoming_see_all_hearings,
        };

		if(selectAll){
			updatedBody.filter_flag= getIsFilterSelected()
            updatedBody.filters = generateFilterObject()
		}
        
        const handleSuccess = () => {
            fetchDeadlineData({ page: pageNumber });
            setSelectAll(false);
            setSelected([])
            setSelectedRows([])
            setReplyFiledLoading(false);
            setNotMyClientLoading(false);
            setOpenReplyFiled(false);
            setOpenNotMyClient(false);
        };
    
        const handleError = (errorResponse) => {
            notification.error({ message: errorResponse?.message ?? "Something went wrong!" });
            setReplyFiledLoading(false);
            setNotMyClientLoading(false);
        };
    
        const commonDispatchProps = {
            body: updatedBody,
            onLoading: () => {
                setReplyFiledLoading(true);
                setNotMyClientLoading(true);
            },
            onSuccess: handleSuccess,
            onError: handleError,
        };
    
        if (filing_document_type === "RENEWAL") {
            delete updatedBody.is_not_my_opponent;
            delete updatedBody.mm_type;
            delete updatedBody.filing_document_type;
            dispatch(updateDeadlineManagementRenewalReplyFiled(commonDispatchProps));
        } else {
            dispatch(updateDeadlineManagementReplyFiledOrNotMyClient(commonDispatchProps));
        }
    };

	const handleContainsTermChange = (event) => {
		let body = {
			contains_term: event.target.value,
			filing_document_type: filing_document_type,
			dashboardType: dashboardType,
		};
		dispatch(updateDeadlineManagementContainsTerm(body));
	};

	const handleSeeAllHearingsChange = (event) => {
		let body = {
			checked: event.target.checked,
			filing_document_type: filing_document_type,
			dashboardType: dashboardType,
		};
		setSelectAll(false)
		setSelectedRows([])
		setSelected([])
		dispatch(updateDeadlineManagementSeeAllHearings(body));
		// data api call
		let bodyForUpcoming = {
			upcoming: event.target.checked,
			page: 1, // adding page for data api
		};
		fetchDeadlineData?.(bodyForUpcoming);
		// handleResetFilters(bodyForUpcoming);
		fetchFilters(bodyForUpcoming);
	};

	const handleReplyFiled = () => {
		setOpenReplyFiled(true);
	};

    const handleNotMyClient = () => {
		setOpenNotMyClient(true);
	};

    const handleCreateReport = (callBackBody) =>{
		const isRenewal = filing_document_type === "RENEWAL";
		
        let updatedBody = {
            report_columns: callBackBody.report_columns,
            report_type: callBackBody.report_format,
            report_name: callBackBody.report_name,
			pseudo_flag: changeSwitch,
            upcoming : !upcoming_see_all_hearings,
			mm_type: deadlineMMType,
			filing_document_type: filing_document_type,
            select_all: selectAll,
			selected_ids: selectAll ? [] : selected ?? [],
			filter_flag: false,
			filters: {},
		};

		if(selectAll){
			updatedBody.filter_flag= getIsFilterSelected()
            updatedBody.filters = generateFilterObject()
		}

		if (isRenewal) {
			delete updatedBody.is_not_my_opponent;
			delete updatedBody.mm_type;
			delete updatedBody.filing_document_type;
		}
	
		const dispatchAction = isRenewal
			? updateDeadlineManagementRenewalCreateReport
			: updateDeadlineManagementCreateReport;
	
		dispatch(
			dispatchAction({
				body: updatedBody,
				onLoading: () => setOpenCreateReport(true),
				onSuccess: (response) => {
					notification.success({
						message: response?.message ?? "Something went wrong!",
					});
					setOpenCreateReport(false);
				},
				onError: (errorResponse) => {
					notification.error({
						message: errorResponse?.message ?? "Something went wrong!",
					});
					setOpenCreateReport(false);
				},
			})
		);
    }

    const handleUpdateCheckbox = (checked, key)=>{
        let body = {
			checked: checked,
			filing_document_type: filing_document_type,
			dashboardType: dashboardType,
            filter_key: key
		};
		dispatch(updateDeadlineManagementBooleanFilterValues(body));
    }

	const isReplyFiledDisabled =
		!selected.length || !tableData.length || (selectAll && !getIsFilterSelected());

    const propsForMultipleCheckbox = {
        handleUpdateCheckbox,
        serviced: serviced,
	    multipleOpponent: multiple_opponent_flag,
	    showAllOpponent: show_all_opponents,
	    showServicedCheckbox: true,
	    showMultipleOpponentCheckbox: dashboardType === "third_party_opposed_trademark",
	    showAllOpponentCheckbox: dashboardType === "third_party_opposed_trademark",
    }

	const handleChangeSwitch = (checked) => {
		setRenewalChangeSwitch(checked);
		setSelectAll(false)
		setSelectedRows([])
		setSelected([])
		// data api call
		let bodyForPseudo = {
			changedPseudo: checked,
			page: 1, // adding page for data api
		};
		fetchDeadlineData?.(bodyForPseudo);
		// handleResetFilters(bodyForPseudo);
		fetchFilters(bodyForPseudo);
	}
    
	const primaryFilters = (
		<Row style={{ width: "100%" }} gutter={[8, 8]} wrap>
			<Col lg={16}>
				<Input
					placeholder={"Application No/Name"}
					onChange={(event) => handleContainsTermChange(event)}
					value={contains_term}
					allowClear
					className="search-value-input-field"
					style={{
						width: 220,
						background: "#F5F6F8",
						borderRadius: "0px",
					}}
				/>
				&emsp;
				{filing_document_type !== "RENEWAL" && (
					<>
						<MultipleCheckboxComponents {...propsForMultipleCheckbox} /> &nbsp;
					</>
				)}
				<SelectContainer
					filteredData={trademarkTypeConfig}
					key={trademarkTypeConfig.key}
					loading={trademarkTypeConfig.loading}
					handleGetSingleFilter={getSingleFilterData}
					filing_document_type={filing_document_type}
					dashboardType={dashboardType}
				/>
				&nbsp;
				<SelectContainer
					filteredData={trademarkStateConfig}
					key={trademarkStateConfig.key}
					loading={trademarkStateConfig.loading}
					handleGetSingleFilter={getSingleFilterData}
					filing_document_type={filing_document_type}
					dashboardType={dashboardType}
				/>
				&nbsp;
				<Checkbox
					checked={upcoming_see_all_hearings ?? false}
					onChange={(event) => handleSeeAllHearingsChange(event)}>
					See all {filing_document_type === "RENEWAL" ? "renewals" :"pending replies"}
				</Checkbox>
				&nbsp;
				<Button
					type="primary"
					icon={<FilterFilled />}
					disabled={!getIsFilterSelected() || loading}
					onClick={handleApplyFilters}
					loading={loading}>
					Apply
				</Button>
				&nbsp;
				<Button
					type="text"
					danger
					onClick={() => handleResetFilters()}
					disabled={!isFilterApplied}>
					Reset All
				</Button>
				&nbsp;
				<Tooltip title="Deselect All">
					<Button
						danger
						onClick={() => {
							setSelectAll(false);
							setSelected([]);
							// setSelectedRowKeys([]);
							setSelectedRows([]);
						}}
						disabled={!selected.length}
						icon={
							<div
								style={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
								}}>
								<MinusCircleOutlined style={{ margin: 0, fontSize: 16 }} />
							</div>
						}
					/>
				</Tooltip>
			</Col>
			<Col
				lg={8}
				style={{
					display: "flex",
					justifyContent: "flex-end",
					gap: selectAll ? 6 : 4,
				}}>
				{(filing_document_type === "RENEWAL" && userSubscriptions.manager.includes("PSEUDO_TRADEMARK")) && (
					<div
						style={{
							display: "flex",
							alignItems: "center",
							flexWrap: "wrap",
							gap: "8px",
							marginRight:"10px"
						}}>
						<span style={!renewalChangeSwitch ?{ fontWeight: 600 }:{}}>IP INDIA</span>
						<Switch
							className="change-manager-type"
							onChange={(event) => handleChangeSwitch(event)}
							checked={renewalChangeSwitch}
						/>
						<span style={renewalChangeSwitch ?{ fontWeight: 600 }:{}}>CUSTOM</span>
					</div>
				)}
				{dashboardType === "third_party_opposed_trademark" && (
					<>
						<Badge
							overflowCount={9999}
							count={selectAll ? "ALL" : selected.length}
							style={{ marginTop: "5px" }}>
							<Button
								icon={
									<UserDeleteOutlined
										twoToneColor={isReplyFiledDisabled ? "lightGrey" : ""}
									/>
								}
								onClick={handleNotMyClient}
								disabled={isReplyFiledDisabled}>
								Not My Client
							</Button>
						</Badge>
						&nbsp;
					</>
				)}
				<Badge
					overflowCount={9999}
					count={selectAll ? "ALL" : selected.length}
					style={{ marginTop: "5px" }}>
					<Button
						icon={
							<CheckCircleTwoTone
								twoToneColor={isReplyFiledDisabled ? "lightGrey" : ""}
							/>
						}
						onClick={handleReplyFiled}
						disabled={isReplyFiledDisabled}>
						Reply Filed
					</Button>
				</Badge>
				&nbsp;
				<Badge
					overflowCount={9999}
					count={selectAll ? "ALL" : selected.length}
					style={{ marginTop: "5px" }}>
					<Button
						onClick={() => setOpenCreateReport(true)}
						disabled={!selected.length || !tableData.length}>
						Create Report
					</Button>
				</Badge>
			</Col>
		</Row>
	);

    const replyFiledDataProps = {
        open: openReplyFiled,
        setOpen: setOpenReplyFiled,
        data: selectedRows,
        handleSubmit: generateReplyFiledAndSetNotMyOpponent,
        loading: replyFiledloading,
        setLoading: setReplyFiledLoading
    }

    const notMyClientDataProps = {
        open: openNotMyClient,
        setOpen: setOpenNotMyClient,
        data: selectedRows,
        handleSubmit: generateReplyFiledAndSetNotMyOpponent,
        loading: notMyClientLoading,
        setLoading: setNotMyClientLoading
    }

    const propsForCreateReport = {
        open: openCreateReport,
        setOpen: setOpenCreateReport,
        report_columns: report_columns,
        handleCreateReport,
        showPDF: true
    };

	return (
		<>
            <NotMyClientComponent {...notMyClientDataProps}/>
            <CreateReport {...propsForCreateReport}/>
			<NewReplyFiled {...replyFiledDataProps}/>
			{primaryFilters}
		</>
	);
};

export default DeadlineFilters;
