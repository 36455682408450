import React from "react";
import CommonInfoModal from "../../../commonComponents/modals/commonInfoModal";

const NotMyClientComponent = (props) => {
    const { open, setOpen, single= false, data=[],handleSubmit,loading, setLoading, removeAction = true } = props;

    const singleInfoText = props.applied_for ? (
        <p>
            Are you sure you want to {removeAction ? "remove" : "add"}{" "}
            <span>
                <strong>{data?.applied_for}</strong>
            </span>{" "}
            from this table?
        </p>
    ) : (
        <p>
            Are you sure you want to {removeAction ? "remove" : "add"} selected (
            <span>
                <strong>{data?.application_number}</strong>
            </span>
            ) marks from this table?
        </p>
    );

    const multipleInfoText = (
        <p>
            Are you sure you want to {removeAction ? "remove" : "add"} selected (
            <span>
                <strong>{data?.length ?? 0 }</strong>
            </span>
            ) marks from this table?
        </p>
    )

    const infoText = single ? singleInfoText : multipleInfoText;

    const saveButtonExtraAction = ()=>{
        setLoading(true)
        let body = {
            is_not_my_opponent: removeAction
        }
        handleSubmit?.(body)
    }

    const childProps = {
        open: open,
        setOpen: setOpen,
        loading: loading,
        title:  removeAction ?"Confirm Not My Client" : "Confirm My Client",
        infoText: infoText,
        saveButtonExtraAction: saveButtonExtraAction,
    };

    return <CommonInfoModal {...childProps} />;
};

export default NotMyClientComponent;
