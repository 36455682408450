export const deadline_mm_type = {
    "Portfolio trademark": "Portfolio trademark",
    "Third party Opposed trademark": "Third party Opposed trademark",
}

export const deadline_filing_document_type = {
    "FORMALITIES CHK FAIL": "FORMALITIES CHK FAIL",
    "REPLY TO EXAMINATION REPORT" : "REPLY TO EXAMINATION REPORT",
    "FILING COUNTER STATEMENT": "FILING COUNTER STATEMENT",
    "FILING OF RULE 45 EVIDENCE AFFIDAVIT": "FILING OF RULE 45 EVIDENCE AFFIDAVIT",
    "FILING OF RULE 46 EVIDENCE AFFIDAVIT": "FILING OF RULE 46 EVIDENCE AFFIDAVIT",
    "FILING OF RULE 47 EVIDENCE AFFIDAVIT": "FILING OF RULE 47 EVIDENCE AFFIDAVIT",
    "RENEWAL":"RENEWAL"
}

export const allowed_opponent_column = ["FILING COUNTER STATEMENT", "FILING OF RULE 45 EVIDENCE AFFIDAVIT","FILING OF RULE 46 EVIDENCE AFFIDAVIT","FILING OF RULE 47 EVIDENCE AFFIDAVIT"]

export const allowed_reason_for_notice = ["FORMALITIES CHK FAIL","REPLY TO EXAMINATION REPORT"]