import {
	LOADING,
	SUCCESS,
	ERROR,
} from "../../../../constants/commonConstants/generalConstants";
import {
	EMPTY_DM_FILTER_STRUCTURE,
	GET_DM_FILTER_ALERTS,
	GET_DM_FILTER_CLASSES,
	GET_DM_FILTER_PROPRIETOR,
	GET_DM_FILTER_TAG,
	GET_DM_FILTER_TRADEMARK_OPPONENT,
	GET_DM_FILTER_TRADEMARK_STATES,
	GET_DM_FILTER_TRADEMARK_STATUS,
	GET_DM_FILTER_TRADEMARK_TYPE,
	UPDATE_DM_BOOLEAN_FILTERS_VALUES,
	UPDATE_DM_CONTAINS_TERM,
	UPDATE_DM_FILTER_DATE_VALUES,
	UPDATE_DM_FILTER_NO_TAG,
	UPDATE_DM_FILTER_SEARCH_VALUE,
	UPDATE_DM_FILTER_SELECTED_VALUES,
	UPDATE_DM_IS_FILTER_APPLIED,
	UPDATE_DM_SEE_ALL_HEARINGS,
	GET_DM_FILTER_RENEWAL_ALERTS,
	GET_DM_FILTER_RENEWAL_CLASSES,
	GET_DM_FILTER_RENEWAL_PROPRIETOR,
	GET_DM_FILTER_RENEWAL_TAG,
	GET_DM_FILTER_RENEWAL_TRADEMARK_OPPONENT,
	GET_DM_FILTER_RENEWAL_TRADEMARK_STATES,
	GET_DM_FILTER_RENEWAL_TRADEMARK_STATUS,
	GET_DM_FILTER_RENEWAL_TRADEMARK_TYPE,
} from "./types";

const defaultFilterConfig = {
	proprietorConfig: {
		key: "PROPRIETOR",
		menu_label: "Proprietor",
		data: [],
		metadata: {
			count: 0,
			page: 1,
		},
		loading: false,
		selectedValues: [],
		onlyValues: [],
		excludeValues: [],
		searchValue: "",
	},
	classConfig: {
		key: "CLASSES",
		menu_label: "Classes",
		data: [],
		metadata: {
			count: 0,
			page: 1,
		},
		loading: false,
		selectedValues: [],
		onlyValues: [],
		searchValue: "",
	},
	trademarkTypeConfig: {
		key: "TRADEMARK_TYPE",
		menu_label: "Type",
		data: [],
		metadata: {
			count: 0,
			page: 1,
		},
		loading: false,
		selectedValues: [],
		onlyValues: [],
		searchValue: "",
	},
	tagConfig: {
		key: "TAG",
		menu_label: "Tags",
		data: [],
		metadata: {
			count: 0,
			page: 1,
		},
		loading: false,
		selectedValues: [],
		onlyValues: [],
		searchValue: "",
		andOr: false,
		noTags: false,
	},
	trademarkStatusConfig: {
		key: "TRADEMARK_STATUS",
		menu_label: "Trademark Status",
		data: [],
		metadata: {
			count: 0,
			page: 1,
		},
		loading: false,
		selectedValues: [],
		onlyValues: [],
		searchValue: "",
	},
	trademarkStateConfig: {
		key: "TRADEMARK_STATES",
		menu_label: "State",
		data: [],
		metadata: {
			count: 0,
			page: 1,
		},
		loading: false,
		selectedValues: [],
		onlyValues: [],
		searchValue: "",
	},
	trademarkOpponentConfig: {
		key: "TRADEMARK_OPPONENT",
		menu_label: "Opponents",
		data: [],
		metadata: {
			count: 0,
			page: 1,
		},
		loading: false,
		selectedValues: [],
		onlyValues: [],
		excludeValues: [],
		searchValue: "",
	},
	trademarkAlertConfig: {
		key: "ALERTS",
		menu_label: "Alerts",
		data: [],
		metadata: {
			count: 0,
			page: 1,
		},
		loading: false,
		selectedValues: [],
		onlyValues: [],
		searchValue: "",
	},
	upcoming_see_all_hearings: false,
	contains_term: "",
	isFilterApplied: false,
	dosDueDateConfig: {
		key: "dos_due_date",
		value: {
			from: "",
			to: "",
			dateString: "",
		},
	},
	renewalDueDateConfig: {
		key: "renewal_due_date",
		value: {
			from: "",
			to: "",
			dateString: "",
		},
	},
	validUptoDateConfig: {
		key: "valid_upto_date",
		value: {
			from: "",
			to: "",
			dateString: "",
		},
	},
	douDueDateConfig: {
		key: "dou_due_date",
		value: {
			from: "",
			to: "",
			dateString: "",
		},
	},
	trademarkDateOfApplicationConfig: {
		key: "trademark_date_of_application",
		value: {
			from: "",
			to: "",
			dateString: "",
		},
	},
	multiple_opponent_flag: false,
	show_all_opponents: false,
	serviced: false,
};

const initialState = {
	your_trademark: {
		"FORMALITIES CHK FAIL": {
			config: defaultFilterConfig,
			isFilterRendered: false,
		},
		"REPLY TO EXAMINATION REPORT": {
			config: defaultFilterConfig,
			isFilterRendered: false,
		},
		"FILING COUNTER STATEMENT": {
			config: defaultFilterConfig,
			isFilterRendered: false,
		},
		"FILING OF RULE 46 EVIDENCE AFFIDAVIT": {
			config: defaultFilterConfig,
			isFilterRendered: false,
		},
		RENEWAL: {
			config: defaultFilterConfig,
			isFilterRendered: false,
		},
	},
	third_party_opposed_trademark: {
		"FILING OF RULE 45 EVIDENCE AFFIDAVIT": {
			config: defaultFilterConfig,
			isFilterRendered: false,
		},
		"FILING OF RULE 47 EVIDENCE AFFIDAVIT": {
			config: defaultFilterConfig,
			isFilterRendered: false,
		},
	},
};

const generateUniqueArrayOfObject = (arrayOfObjects) => {
	const uniqueArray = [];
	const seenValues = new Set();

	for (const obj of arrayOfObjects) {
		const value = obj.value;
		if (!seenValues.has(value)) {
			uniqueArray.push(obj);
			seenValues.add(value);
		}
	}
	return uniqueArray;
};

const updateConfig = (state, payload, changes, isFilterRendered) => {
	const { dashboardType, filing_document_type } = payload;
	return {
		...state,
		[dashboardType]: {
			...state[dashboardType],
			[filing_document_type]: {
				...state[dashboardType][filing_document_type],
				config: {
					...state[dashboardType][filing_document_type].config,
					...changes,
				},
				isFilterRendered,
			},
		},
	};
};

export default function deadlineManagementFilters(
	state = initialState,
	action
) {
	switch (action.type) {
		case GET_DM_FILTER_ALERTS:
		case GET_DM_FILTER_CLASSES:
		case GET_DM_FILTER_PROPRIETOR:
		case GET_DM_FILTER_TAG:
		case GET_DM_FILTER_TRADEMARK_STATUS:
		case GET_DM_FILTER_TRADEMARK_STATES:
		case GET_DM_FILTER_TRADEMARK_OPPONENT:
		case GET_DM_FILTER_TRADEMARK_TYPE: {
			const { payload, status } = action;
			const changedConfig =
				Object.entries(
					state?.[payload.dashboardType]?.[payload.filing_document_type]
						?.config ?? {}
				).find((item) => item[1].key === payload.required_filter)?.[0] ?? "";

			if (!changedConfig) return state;

			const commonChanges = {
				data:
					status === SUCCESS
						? payload.page > 1
							? generateUniqueArrayOfObject([
									...state?.[payload.dashboardType]?.[
										payload.filing_document_type
									]?.config?.[changedConfig]?.data,
									...payload.data,
							  ])
							: payload?.data?.data ?? []
						: [],
				metadata: {
					...(payload?.data?.metadata ?? {}),
					page: status === SUCCESS ? payload?.page : 1,
					count: status === ERROR ? 0 : payload?.data?.metadata?.count ?? 0,
				},
				loading: status === LOADING,
			};
			return updateConfig(
				state,
				payload,
				{
					[changedConfig]: {
						...state?.[payload.dashboardType]?.[payload.filing_document_type]
							?.config?.[changedConfig],
						...commonChanges,
					},
				},
				status === SUCCESS
			);
		}

		case UPDATE_DM_CONTAINS_TERM:
			return updateConfig(state, action.payload, {
				contains_term: action.payload.contains_term,
			});

		case UPDATE_DM_SEE_ALL_HEARINGS:
			return updateConfig(state, action.payload, {
				upcoming_see_all_hearings: action.payload.checked,
			});

		case UPDATE_DM_FILTER_SELECTED_VALUES:
			const { payload } = action;
			const changedConfig =
				Object.entries(
					state?.[payload.dashboardType]?.[payload.filing_document_type]
						?.config ?? {}
				).find((item) => item[1].key === payload.required_filter)?.[0] ?? "";

			if (!changedConfig) return state;
			return updateConfig(state, action.payload, {
				[changedConfig]: {
					...state?.[payload.dashboardType]?.[payload.filing_document_type]
						?.config?.[changedConfig],
					selectedValues: action.payload.selectedValues,
					onlyValues: action.payload.onlyValues,
				},
			});

		case UPDATE_DM_FILTER_SEARCH_VALUE: {
			const { payload } = action;
			const changedConfig =
				Object.entries(
					state?.[payload.dashboardType]?.[payload.filing_document_type]
						?.config ?? {}
				).find((item) => item[1].key === payload.required_filter)?.[0] ?? "";

			if (!changedConfig) return state;
			return updateConfig(state, action.payload, {
				[changedConfig]: {
					...state?.[payload.dashboardType]?.[payload.filing_document_type]
						?.config?.[changedConfig],
					searchValue: action.payload.searchValue,
				},
			});
		}

		case UPDATE_DM_FILTER_NO_TAG: {
			const { payload } = action;
			const changedConfig =
				Object.entries(
					state?.[payload.dashboardType]?.[payload.filing_document_type]
						?.config ?? {}
				).find((item) => item[1].key === payload.required_filter)?.[0] ?? "";

			if (!changedConfig) return state;
			return updateConfig(state, action.payload, {
				[changedConfig]: {
					...state?.[payload.dashboardType]?.[payload.filing_document_type]
						?.config?.[changedConfig],
					noTags: action.payload.checked,
				},
			});
		}

		case UPDATE_DM_FILTER_DATE_VALUES: {
			const { payload } = action;
			const changedConfig =
				Object.entries(
					state?.[payload.dashboardType]?.[payload.filing_document_type]
						?.config ?? {}
				).find((item) => item[1].key === payload.required_filter)?.[0] ?? "";

			if (!changedConfig) return state;
			return updateConfig(state, action.payload, {
				[changedConfig]: {
					...state?.[payload.dashboardType]?.[payload.filing_document_type]
						?.config?.[changedConfig],
					value: action.payload.value,
				},
			});
		}

		case UPDATE_DM_IS_FILTER_APPLIED: {
			return updateConfig(state, action.payload, {
				isFilterApplied: action.payload.value,
			});
		}

		case EMPTY_DM_FILTER_STRUCTURE: {
			const { dashboardType, filing_document_type } = action.payload;

			if (
				!state[dashboardType] ||
				!state[dashboardType][filing_document_type]
			) {
				return state;
			}
			return {
				...state,
				[dashboardType]: {
					...state[dashboardType],
					[filing_document_type]: {
						config: {
							proprietorConfig: {
								...state[dashboardType]?.[filing_document_type]?.config?.proprietorConfig,
								selectedValues: [],
								onlyValues: [],
								excludeValues: [],
								searchValue: "",
							},
							classConfig: {
								...state[dashboardType]?.[filing_document_type]?.config?.classConfig,
								selectedValues: [],
								onlyValues: [],
								excludeValues: [],
								searchValue: "",
							},
							trademarkTypeConfig: {
								...state[dashboardType]?.[filing_document_type]?.config?.trademarkTypeConfig,
								selectedValues: [],
								onlyValues: [],
								searchValue: "",
							},
							tagConfig: {
								...state[dashboardType]?.[filing_document_type]?.config?.tagConfig,
								selectedValues: [],
								onlyValues: [],
								searchValue: "",
								andOr: false,
								noTags: false,
							},
							trademarkStatusConfig: {
								...state[dashboardType]?.[filing_document_type]?.config?.trademarkStatusConfig,
								selectedValues: [],
								onlyValues: [],
								searchValue: "",
							},
							trademarkStateConfig: {
								...state[dashboardType]?.[filing_document_type]?.config?.trademarkStateConfig,
								selectedValues: [],
								onlyValues: [],
								searchValue: "",
							},
							trademarkOpponentConfig: {
								...state[dashboardType]?.[filing_document_type]?.config?.trademarkOpponentConfig,
								selectedValues: [],
								onlyValues: [],
								excludeValues: [],
								searchValue: "",
							},
							trademarkAlertConfig: {
								...state[dashboardType]?.[filing_document_type]?.config?.trademarkAlertConfig,
								selectedValues: [],
								onlyValues: [],
								searchValue: "",
							},
							contains_term: "",
							isFilterApplied: false,
							dosDueDateConfig: {
								key: "dos_due_date",
								value: {
									from: "",
									to: "",
									dateString: "",
								},
							},
							renewalDueDateConfig: {
								key: "renewal_due_date",
								value: {
									from: "",
									to: "",
									dateString: "",
								},
							},
							validUptoDateConfig: {
								key: "valid_upto_date",
								value: {
									from: "",
									to: "",
									dateString: "",
								},
							},
							douDueDateConfig: {
								key: "dou_due_date",
								value: {
									from: "",
									to: "",
									dateString: "",
								},
							},
							trademarkDateOfApplicationConfig: {
								key: "trademark_date_of_application",
								value: {
									from: "",
									to: "",
									dateString: "",
								},
							},
							multiple_opponent_flag: false,
							show_all_opponents: false,
							serviced: false,
							upcoming_see_all_hearings:
								state[dashboardType][filing_document_type].config
									.upcoming_see_all_hearings,
						},
						isFilterRendered: false,
					},
				},
			};
		}

		case UPDATE_DM_BOOLEAN_FILTERS_VALUES:
			return updateConfig(state, action.payload, {
				[action.payload.filter_key]: action.payload.checked,
			});

		case GET_DM_FILTER_RENEWAL_ALERTS:
		case GET_DM_FILTER_RENEWAL_CLASSES:
		case GET_DM_FILTER_RENEWAL_PROPRIETOR:
		case GET_DM_FILTER_RENEWAL_TAG:
		case GET_DM_FILTER_RENEWAL_TRADEMARK_STATUS:
		case GET_DM_FILTER_RENEWAL_TRADEMARK_STATES:
		case GET_DM_FILTER_RENEWAL_TRADEMARK_OPPONENT:
		case GET_DM_FILTER_RENEWAL_TRADEMARK_TYPE: {
			const { payload, status } = action;
			const changedConfig =
				Object.entries(
					state?.[payload.dashboardType]?.[payload.filing_document_type]
						?.config ?? {}
				).find((item) => item[1].key === payload.required_filter)?.[0] ?? "";

			if (!changedConfig) return state;

			const commonChanges = {
				data:
					status === SUCCESS
						? payload.page > 1
							? generateUniqueArrayOfObject([
									...state?.[payload.dashboardType]?.[
										payload.filing_document_type
									]?.config?.[changedConfig]?.data,
									...payload.data,
							  ])
							: payload?.data?.data ?? []
						: [],
				metadata: {
					...(payload?.data?.metadata ?? {}),
					page: status === SUCCESS ? payload?.page : 1,
					count: status === ERROR ? 0 : payload?.data?.metadata?.count ?? 0,
				},
				loading: status === LOADING,
			};
			return updateConfig(
				state,
				payload,
				{
					[changedConfig]: {
						...state?.[payload.dashboardType]?.[payload.filing_document_type]
							?.config?.[changedConfig],
						...commonChanges,
					},
				},
				status === SUCCESS
			);
		}

		default:
			return state;
	}
}
